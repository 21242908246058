import { TDocumentDefinitions } from "pdfmake/interfaces";
import moment from "moment";

// Common
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";

import { formatDatetime } from "react-lib/utils/dateUtils";

type FormLaboratoryTestResultProps = {
  labResultList: LabResultList;
  parentLabID: string[];
  patient: Record<string, any>;
  receivedDatetime: string;
  requestedBy: string;
  requestedDatetime: string;
};

type LabResultList = Record<
  string,
  {
    id: number;
    items: {
      authorize_user_fullname?: string;
      children?: boolean;
      dates?: {
        status: string;
        value: string;
      }[];
      header?: boolean;
      last: boolean;
      name: string;
      ref_value: string;
      report_user_fullname?: string;
      unit: string;
    }[];
  }
>;

const genderMapping: Record<string, string> = {
  F: "หญิง",
  M: "ชาย",
};

const FORM_NAME = "FormLaboratoryTestResult";
const LOGO_HEIGHT = 30;

const FormLaboratoryTestResult = async (
  props: FormLaboratoryTestResultProps
): Promise<TDocumentDefinitions> => {
  const companyLogoForm = await CompanyLogoForm({
    font: "THSarabunNew",
    form: FORM_NAME,
    height: LOGO_HEIGHT,
    showAddress: ["CU"],
  });
  const { font, fontSizes, images, lineHeights, hideLogo } = companyLogoForm;

  const items = props.labResultList?.flatMap((item: any) => {
    return [{ ...item }, ...item.children.map((child: any) => ({ ...child }))];
  });

  const gender = genderMapping[props.patient.gender] || "ไม่ระบุ";

  const hideReportedColumn = !items.some(
    (item) => item.central_lab_result_authorize_user_fullname !== ""
  );
  const widths = hideReportedColumn
    ? ["37.75%", "17.75%", "4.5%", "23.75%", "16.25%"]
    : ["24.5%", "9%", "5.5%", "15.5%", "10.5%", "35%"];

  console.log("FormLaboratoryTestResult", props);

  return {
    defaultStyle: {
      font,
      fontSize: fontSizes[14],
      lineHeight: lineHeights[1],
    },
    images: {
      ...images,
    },
    pageMargins: [10, 100, 10, 25],
    pageOrientation: "landscape",
    pageSize: "A5",
    styles: {
      fieldHeader: { bold: true, fontSize: fontSizes[17] },
      fieldKey: { bold: true },
      miniField: { fontSize: fontSizes[12] },
      tableHeader: { bold: true },
      tableValue: { fontSize: fontSizes[13] },
    },
    header: () => ({
      margin: [10, 10, 10, 0],
      stack: [
        {
          ...companyLogoForm,
          margin: !hideLogo ? [0, -3, 0, 0] : [0, 25, 0, 0],
        },
        {
          alignment: "center",
          marginTop: -16,
          style: "fieldHeader",
          text: "ผลการทดสอบทางห้องปฏิบัติการ",
          width: "*",
        },
        {
          margin: [10, 7.5, 10, 0],
          stack: [
            {
              columns: [
                {
                  columns: [
                    {
                      text: [
                        { style: "fieldKey", text: "ชื่อผู้ป่วย: " },
                        { text: props.patient.full_name },
                      ],
                      width: "*",
                    },
                    {
                      alignment: "right",
                      text: [{ style: "fieldKey", text: "HN: " }, { text: props.patient.hn }],
                      width: "auto",
                    },
                  ],
                  width: "47.5%",
                },
                { text: "", width: "5%" },
                {
                  columns: [
                    {
                      text: [
                        { style: "fieldKey", text: "อายุ: " },
                        { text: props.patient.age },
                        { text: " ปี" },
                      ],
                      width: "30%",
                    },
                    {
                      text: [{ style: "fieldKey", text: "เพศ: " }, { text: gender }],
                      width: "30%",
                    },
                    {
                      text: [
                        { style: "fieldKey", text: "วันเกิด: " },
                        { text: props.patient.birthdate },
                      ],
                      width: "40%",
                    },
                  ],
                  width: "47.5%",
                },
              ],
            },
            {
              columns: [
                {
                  columns: [
                    {
                      text: [
                        { style: "fieldKey", text: "Requested by: " },
                        { text: props.requestedBy },
                      ],
                      width: "auto",
                    },
                    {
                      alignment: "right",
                      marginLeft: 20,
                      text: props.requestedDatetime,
                      width: "auto",
                    },
                  ],
                  width: "66.75%",
                },
                {
                  columns: [
                    {
                      text: [
                        { style: "fieldKey", text: "Received Date/Time: " },
                        { text: props.receivedDatetime },
                      ],
                      width: "*",
                    },
                  ],
                  width: "*",
                },
              ],
            },
          ],
        },
      ],
    }),
    content: [
      {
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths,
          body: [
            [
              { style: "tableHeader", text: "Lab name" },
              { style: "tableHeader", text: "Result" },
              { style: "tableHeader", text: " " },
              { style: "tableHeader", text: "Reference value" },
              { alignment: "center", style: "tableHeader", text: "Unit" },
              ...(hideReportedColumn
                ? []
                : [{ style: "tableHeader", text: "Reported/Authorized" }]),
            ],
            ...items.map((item: any) => {
              const border = !item.central_lab_order_item_is_child
                ? [false, true, false, false]
                : [false, false, false, false];
              const borderColorGrey = ["#646464", "#646464", "#646464", "#646464"];
              return [
                {
                  bold: !item.central_lab_order_item_is_child,
                  border,
                  borderColor: borderColorGrey,
                  marginLeft: item.central_lab_order_item_is_child ? 7 : 0,
                  style: "tableValue",
                  text: item.name,
                },
                {
                  border,
                  borderColor: borderColorGrey,
                  style: "tableValue",
                  text: item.central_lab_result_value || "",
                },
                {
                  border,
                  borderColor: borderColorGrey,
                  style: "tableValue",
                  text: item.result_status || "",
                },
                {
                  border,
                  borderColor: borderColorGrey,
                  style: "tableValue",
                  text: item.ref_value_txt || "",
                  bold: true,
                  preserveLeadingSpaces: true,
                },
                {
                  alignment: "center",
                  border,
                  borderColor: borderColorGrey,
                  style: "tableValue",
                  text: item.unit,
                },
                ...(hideReportedColumn
                  ? []
                  : [
                      {
                        border,
                        borderColor: borderColorGrey,
                        style: "tableValue",
                        text: item.central_lab_order_item_is_child
                          ? ""
                          : `${item.central_lab_result_report_user_fullname || ""}/ ${
                              item.central_lab_result_authorize_user_fullname || ""
                            }`,
                      },
                    ]),
              ];
            }),
          ],
        },
        layout: {
          hLineWidth: () => 0.25,
          paddingBottom: () => 2,
          paddingTop: () => 2,
          vLineWidth: () => 0,
        },
      },
    ],
    footer: (currentPage, pageCount) => ({
      margin: [10, 0, 10, 0],
      table: {
        widths: ["100%"],
        body: [
          [
            {
              border: [false, true, false, false],
              columns: [
                { text: "Print Date/Time: ", width: "auto" },
                { marginLeft: 20, text: formatDatetime(moment(), true), width: "*" },
                { text: `หน้า ${currentPage}/${pageCount}`, width: "auto" },
              ],
              marginTop: 2.5,
              style: "miniField",
            },
          ],
        ],
      },
      layout: {
        hLineWidth: () => 0.25,
      },
    }),
  };
};

export default FormLaboratoryTestResult;
