import React, {
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Button, Form, Icon, Label, Input, Popup } from "semantic-ui-react";

// UX
import CardSpecimenCollector from "./CardSpecimenCollector";
import CardSpecimenCollectorListUX from "./CardSpecimenCollectorListUX";

// Common
import ModInfo from "react-lib/apps/common/ModInfo";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

const styles = {
  blueLabel: {
    padding: "0.75rem 0.5rem",
    background: "#e6f7fe",
    width: "100%",
    minWidth: "90px",
  },
  fieldLabel: {
    color: "grey",
    minWidth: "max-content",
  },
};

const CardLabPanel = (props: any) => {
  const intl = useIntl();
  const [labOrder, setLabOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [openModInfo, setOpenModInfo] = useState<boolean>(false);

  const cardSpecimenCollectorRef = useRef<any>();

  useEffect(() => {
    if (
      props.errorMessage?.LabOrderArrive !== null &&
      props.errorMessage?.LabOrderArrive !== undefined
    ) {
      setLoading(false);

      props.onEvent({
        message: "LabOrderArrive",
        params: { action: "clearMessage" },
      });
      // toast.error("รับเข้า Lab Order ไม่สำเร็จ");
    }
    if (
      props.successMessage?.LabOrderArrive !== null &&
      props.successMessage?.LabOrderArrive !== undefined
    ) {
      setLoading(false);
      setLabOrder(true);

      setTimeout(() => {
        setLabOrder(false);
      }, 2000);

      props.onEvent({
        message: "LabOrderArrive",
        params: { action: "clearMessage" },
      });
      // toast.success("รับเข้า Lab Order สำเร็จ");
    }
  }, [
    props.successMessage?.LabOrderArrive,
    props.errorMessage?.LabOrderArrive,
  ]);

  useEffect(() => {
    // props.onEvent({ message: "ScanBarcodeStickerSpecimen", params: {} });
    props.onEvent({
      message: "GetLabOrderQueue",
      params: {
        action: "search",
        hn: props.selectedEncounter?.hn,
      },
    });
  }, []);

  const labOrderQueue = useMemo(() => {
    return (props?.labOrderQueue?.labOrderQueueList || [])
      .sort((a: any, b: any) =>
        a.highest_urgency <= b.highest_urgency ? 1 : -1
      )
      .map((item: any) => ({
        ...item,
        labId: item.full_access_number,
        arrive_time: formatDatetime(item.arrive_time_iso, true),
        status_name: (
          <div>
            {item.status_name}
            {item.order_item_have_rejected && <Label color="red">REJECT</Label>}
          </div>
        ),
        doctor_name: (
          <div
            style={{
              display: "flex",
              ...(item.highest_urgency === "STAT" && { color: "red" }),
            }}
          >
            <div style={{ width: "80%" }}>{item.order_by_name}</div>
            <div style={{ width: "20%" }}>
              {item.student && item.need_approve ? (
                <img src="/static/images/drugOrder/need_approve.png" />
              ) : (
                item.student &&
                !item.need_approve && (
                  <Popup
                    content={
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            color: "#898383",
                            fontSize: "12px",
                          }}
                        >
                          ตรวจสอบโดย
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {item.approved_by_name}
                        </div>
                      </div>
                    }
                    on="hover"
                    pinned
                    position="bottom center"
                    trigger={
                      <img src="/static/images/drugOrder/approve_check_out_line.png" />
                    }
                  />
                )
              )}
            </div>
          </div>
        ),
      }));
  }, [props?.labOrderQueue?.labOrderQueueList]);

  const isStatusCancel = useMemo(() => {
    return props.selectedLabOrderWorking?.status_name === "ยกเลิก";
  }, [props.selectedLabOrderWorking?.status_name]);

  const handleSearchBarcode = (text: string) => {
    props.onEvent({
      message: "ScanBarcodeStickerSpecimen",
      params: {
        action: "scan",
        searchText: text,
        specimenList: cardSpecimenCollectorRef.current?.specimenList || [],
        callback: (status: "SUCCESS" | "ERROR") => {
          if (status === "SUCCESS") {
            setSearchText("");
          } else {
            setSearchText(searchText.trim());
            setOpenModInfo(true);
          }
        },
      },
    });
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(false);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const value = (e.target as any).value || "";

      handleSearchBarcode(value);
      setSearchText(value);
    }
  };

  const handleGetLabOrderArrive = () => {
    setLoading(true);

    props.onEvent({
      message: "LabOrderArrive",
      params: { action: "getLabOrderArrive" },
    });
  };

  const handleGetRowProps = (state: any, rowInfo: any) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id &&
          rowInfo?.original?.id === props.selectedLabOrderWorking?.id
            ? "#cccccc"
            : "white",
        color: rowInfo?.original?.highest_urgency === "STAT" ? "red" : "",
      },
      onClick: () => {
        props.setProp("selectedLabOrderWorking", rowInfo?.original?.id);
        props.onEvent({
          message: "LabOrderArrive",
          params: {
            action: "getDataLabOrderArrive",
            labOrderId: rowInfo?.original?.id,
            labOrder: rowInfo?.original,
          },
        });
      },
    };
  };

  return (
    <div id="CardLabPanel" style={{ height: "94vh" }}>
      <div style={{ padding: "10px", minWidth: "820px" }}>
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
            alignItems: "center",
            fontSize: "1.2rem",
            fontWeight: "bold",
          }}
        >
          <div style={{ marginRight: "10px" }}>{intl.formatMessage({ id: "รายการ Lab Order" })}</div>
          <div style={{ flex: 1 }}></div>

          <div style={{ marginRight: "10px" }}>{intl.formatMessage({ id: "สแกนรหัส Specimen (tube) ID" })}</div>
          <Input
            value={searchText}
            placeholder="Tube Sticker ID"
            size="mini"
            onKeyPress={handleKeyPress}
            onChange={(e: any, v: any) => {
              setSearchText(v.value);
            }}
          ></Input>
          <Button
            icon="search"
            color="blue"
            onClick={() => handleSearchBarcode(searchText)}
          ></Button>
        </div>

        <div style={{ margin: "10px 0px 20px" }}>
          <CardSpecimenCollectorListUX
            labOrderQueue={labOrderQueue}
            rowProps={handleGetRowProps}
            languageUX={props.languageUX}
          />
        </div>

        <div
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            marginBottom: "0.75rem",
            display: "flex",
          }}
        >
          <div>Lab order</div>

          <div style={{ marginLeft: "1rem" }}>
            {props.selectedLabOrderWorking?.need_approve === false &&
            props.selectedLabOrderWorking?.approved_by_name ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={"/static/images/drugOrder/approve_check.png"}
                  style={{ marginRight: "5px" }}
                />
                <label
                  style={{
                    color: "green",
                    marginRight: "10px",
                  }}
                >
                  APPROVE
                </label>
                <div style={{ display: "flex", color: "black" }}>
                  <label>
                    {"[ "}
                    <span style={{ color: "#898383" }}>{intl.formatMessage({id: "โดย"})}{" "}</span>
                    {props.selectedLabOrderWorking?.approved_by_name} {" ]"}
                  </label>
                </div>
              </div>
            ) : (
              props.selectedLabOrderWorking?.need_approve === true && (
                <div
                  style={{
                    color: "gray",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={"/static/images/drugOrder/need_approve.png"}
                    style={{ marginRight: "5px" }}
                  />
                  <label>NEED APPROVE</label>
                </div>
              )
            )}
          </div>
        </div>
        <Form className="lab-order-detail">
          <Form.Group style={{ alignItems: "baseline", margin: 0 }}>
            <Form.Field style={{ minWidth: "max-content" }}>
              <Button
                size="mini"
                color="google plus"
                loading={loading}
                disabled={
                  !!(isStatusCancel ||
                  props.selectedLabOrderWorking?.arrive_time_iso)
                }
                style={{ padding: "10px" }}
                onClick={handleGetLabOrderArrive}
              >
                {labOrder ? (
                  <div style={{ width: "50px" }}>
                    <Icon name="check"></Icon>
                  </div>
                ) : (
                  "รับเข้า Lab Order"
                )}
              </Button>
            </Form.Field>
            <Form.Field>
              <Form>
                <Form.Group inline>
                  <Form.Field className="field-label" style={styles.fieldLabel}>
                    วันที่
                  </Form.Field>
                  <Form.Field>
                    <Label style={styles.blueLabel}>
                      {props.selectedLabOrderWorking?.date || "\u00a0"}
                    </Label>
                  </Form.Field>
                  <Form.Field className="field-label" style={styles.fieldLabel}>
                    สถานะ
                  </Form.Field>
                  <Form.Field>
                    <Label style={styles.blueLabel}>
                      {props.selectedLabOrderWorking?.status_name || "\u00a0"}
                    </Label>
                  </Form.Field>
                  <Form.Field className="field-label" style={styles.fieldLabel}>
                    Arrive Time
                  </Form.Field>
                  <Form.Field>
                    <Label style={styles.blueLabel}>
                      {formatDatetime(
                        props.selectedLabOrderWorking?.arrive_time_iso,
                        true
                      ) || "\u00a0"}
                    </Label>
                  </Form.Field>
                  <Form.Field className="field-label" style={styles.fieldLabel}>
                    Division
                  </Form.Field>
                  <Form.Field>
                    <Label style={styles.blueLabel}>
                      {props.selectedLabOrderWorking?.order_div_name ||
                        "\u00a0"}
                    </Label>
                  </Form.Field>
                  <Form.Field className="field-label" style={styles.fieldLabel}>
                    แพทย์
                  </Form.Field>
                  <Form.Field>
                    <Label style={styles.blueLabel}>
                      {props.selectedLabOrderWorking?.doctor_name || "\u00a0"}
                    </Label>
                  </Form.Field>
                </Form.Group>

                <Form.Group inline>
                  <Form.Field className="field-label" style={styles.fieldLabel}>
                    การชำระเงิน
                  </Form.Field>

                  <Form.Field style={{ marginRight: "1rem" }}>
                    <Label
                      style={{
                        ...styles.blueLabel,
                        display:
                          props.selectedLabOrderWorking?.payment_status_name ===
                          undefined
                            ? "none"
                            : "",
                        ...(props.selectedLabOrderWorking
                          ?.payment_status_name === "PAID"
                          ? { background: "green", color: "white" }
                          : { background: "#fffbc5" }),
                      }}
                    >
                      {props.selectedLabOrderWorking?.payment_status_name ===
                      "PAID"
                        ? props.selectedLabOrderWorking?.payment_status_label
                        : "ยังไม่ชำระเงิน"}
                    </Label>
                  </Form.Field>
                  <Form.Field className="field-label" style={styles.fieldLabel}>
                    <label>Remark</label>
                  </Form.Field>
                  <Form.Field width={11} style={styles.fieldLabel}>
                    {props.selectedLabOrderWorking?.note || ""}
                  </Form.Field>
                </Form.Group>
              </Form>
            </Form.Field>
          </Form.Group>
        </Form>
      </div>

      {props.selectedLabOrderWorking && (
        <div style={{ padding: "10px 10px 30px" }}>
          <CardSpecimenCollector
            ref={cardSpecimenCollectorRef}
            // function
            setProp={props.setProp}
            onEvent={props.onEvent}
            // common data
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            django={props.django}
            // data
            selectedLabOrderWorking={props.selectedLabOrderWorking}
            selectedContainer={props.selectedContainer}
            specimenLogs={props.specimenLogs}
            specimenCollectorMasterData={props.specimenCollectorMasterData}
            selectedDivision={props.selectedDivision}
            selectedEncounter={props.selectedEncounter}
            // config
            readOnly={isStatusCancel}
            languageUX={props.languageUX}
          />
        </div>
      )}

      <ModInfo
        open={openModInfo}
        titleColor={"red"}
        titleName={intl.formatMessage({ id: "ไม่พบรหัสสิ่งส่งตรวจ (Specimen ID)" })}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        {/* <ErrorMessage error={openModError.error} /> */}
      </ModInfo>

      {/* <div style={{ padding: "10px" }}>
        <CardLabResult
          setProp={props.setProp}
          onEvent={props.onEvent}
          labOrderItems={props.labOrderItems || []}
          // To be deprecated
          selectedLabOrderItem={props.selectedLabOrderItem}
          selectedLabOrderItemChild={props.selectedLabOrderItemChild}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
        />
      </div> */}
    </div>
  );
};

export default CardLabPanel;
