import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  CSSProperties,
  SyntheticEvent,
} from "react";
import {
  Checkbox,
  Label,
  CheckboxProps,
  Icon,
  Popup,
  Modal,
} from "semantic-ui-react";

// UX
import CardLabOrderQueueUX from "./CardLabOrderQueueUX";
import { approveOption } from "react-lib/apps/HISV3/TPD/CardDrugOrderQueue";
import CardLabReport from "./CardLabReport";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import LoadingIcon from "../HCU/LoadingIcon";

// Utils
import { adToBe, formatDatetime } from "react-lib/utils/dateUtils";

// Config
import CONFIG from "config/config";
import { useIntl } from "react-intl";

// Types
type CardLabOrderQueueProps = {
  setProp: (key: string, value: any) => any;
  onEvent: (e: any) => any;

  // data
  django?: Record<string, any>;
  division?: any;
  labOrderQueue?: Record<string, any>;
  selectedLabOrderWorking?: Record<string, any>;
  layout?: number;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // options
  masterOptions?: Record<string, any>;
  // style
  style?: CSSProperties;
  tableHeight?: string | number;
  size?: "max";
  // callback
  forward?: () => any;
} & {
  // * CardLabReport
  // seq
  runSequence: any;
  LabReportSequence?: any;
  // data
  permissionLabResultConfidential?: boolean;
  // CommonInterface
  selectedPatient?: Record<string, any>;
  searchedItemListWithKey?: Record<string, any>;
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
};

// Images
const IMAGES = {
  edit: "/static/images/paper-edit-outline.png",
};

// Const
export const ENCOUNTER_OPTIONS = [
  { key: "ALL", value: "ALL", text: "แสดงทั้งหมด" },
  { key: "OPD", value: "OPD", text: "OPD" },
  { key: "IPD", value: "IPD", text: "IPD" },
  { key: "SS", value: "SS", text: "SS" },
  { key: "ER", value: "ER", text: "ER" },
  { key: "OTH", value: "OTH", text: "OTH" },
  { key: "HCU", value: "HCU", text: "HCU" },
  { key: "IME", value: "IME", text: "IME" },
  { key: "HID", value: "HID", text: "HID" },
];

const LAB_OPTIONS = [
  { key: "ALL", value: "ALL", text: "แสดงทุกประเภท" },
  { key: "INLAB", value: "INLAB", text: "In lab" },
  { key: "OUTLAB", value: "OUTLAB", text: "Out lab" },
  { key: "PHAYATHAI", value: "PHAYATHAI", text: "พญาไท" },
];

const STATUS_OPTIONS = [
  { key: "ALL", value: "ALL", text: "ทุกสถานะ" },
  { key: 1, value: 1, text: "ร้องขอทำ Lab" },
  { key: 2, value: 2, text: "รับเข้าห้องเก็บ Specimen" },
  { key: 3, value: 3, text: "พิมพ์สติ๊กเกอร์แล้ว" },
  { key: 4, value: 4, text: "เก็บ Specimen แล้ว" },
  { key: 6, value: 6, text: "Specimen Center ได้รับ Specimen" },
  { key: 5, value: 5, text: "ได้รับผล Lab" },
  { key: 9, value: 9, text: "ยกเลิก" },
  { key: 10, value: 10, text: "Specimen ถูกปฏิเสธ" },
];

const URGENCY_OPTIONS = [
  { key: "ALL", value: "ALL", text: "แสดงทั้งหมด" },
  { key: "1", value: "1", text: "ROUTINE" },
  { key: "2", value: "2", text: "STAT" },
  { key: "3", value: "3", text: "FAST_TRACK" },
];

const CARD_LAB_ORDER_QUEUE = "CardLabOrderQueue";

const CardLabOrderQueue = (props: CardLabOrderQueueProps) => {
  const intl = useIntl();
  const [checkedIds, setCheckedIds] = useState<number[]>([]);

  // Effect
  useEffect(() => {
    const getLabOrderQueue = async () => {
      // * Init
      props.onEvent({ message: "GetLabOrderQueue", params: {} });

      const selectedDivision = props.division ? props.division : "ALL";

      // * Default search
      await props.setProp(`labOrderQueue`, {
        ...props.labOrderQueue,
        selectedDivision,
        checkSpecimen: false,
        checkSpecimenReject: false,
      });

      props.onEvent({
        message: "GetLabOrderQueue",
        params: {
          action: "search",
          buttonLoadKey: `${CARD_LAB_ORDER_QUEUE}_SEARCH`,
        },
      });
    };

    getLabOrderQueue();
  }, []);

  // Callback
  const handleCheckedSpecimenTube = useCallback(
    (id: number, checked?: boolean) => {
      if (checked) {
        setCheckedIds([...new Set([...checkedIds, id])]);
      } else {
        setCheckedIds(checkedIds.filter((item) => item !== id));
      }
    },
    [checkedIds]
  );

  const handleOpenLabReport = useCallback(
    (order: any) => (e: any) => {
      e.stopPropagation();

      props.onEvent({
        message: "GetLabOrderQueue",
        params: {
          action: "OPEN_LAB_REPORT",
          order,
        },
      });
    },
    []
  );

  const handleRowPropsCrossMatch = useCallback(
    (state: any, rowInfo: any) => {
      const original = rowInfo?.original;

      return {
        style: {
          backgroundColor:
            original?.id && original?.id === props.selectedLabOrderWorking?.id
              ? "#cccccc"
              : "white",
          color: original?.highest_urgency === "STAT" ? "red" : "",
        },
        onClick: () => {
          if (original?.order_status_name !== "APPOINTMENT" && original?.id) {
            props.onEvent({
              message: "SelectLabOrderWorking",
              params: {
                order: original,
                goToMenu: "Specimen Collection",
              },
            });
            props.layout === 0 && props.forward?.();
          }
        },
      };
    },
    [props.layout, props.selectedLabOrderWorking]
  );

  const handleColumnProps = useCallback(
    (state: any, rowInfo: any, column: any) => {
      const original = rowInfo?.original;

      return {
        style: {
          backgroundColor:
            original?.id && original?.id === props.selectedLabOrderWorking?.id
              ? "#cccccc"
              : "white",
          color: original?.highest_urgency === "STAT" ? "red" : "",
        },
        onClick: () => {
          if (original?.id) {
            if (column.id === "check") {
              console.log(" handleCheckedSpecimenTube check return");
              // return handleCheckedSpecimenTube(
              //   original?.id,
              //   !original?.check?.props?.children?.props?.checked
              // );
              return;
            }

            props.onEvent({
              message: "SelectLabOrderWorking",
              params: {
                order: original,
                goToMenu: "Specimen Collection",
              },
            });

            props.layout === 0 && props.forward?.();
          }
        },
      };
    },
    []
  );

  const handlePrintLabResult = useCallback(
    (item: Record<string, any>, index: number) => () => {
      props.onEvent({
        message: "HandlePrintLabResult",
        params: { card: CARD_LAB_ORDER_QUEUE, index, order: item },
      });
    },
    []
  );

  // Memo
  const showLabReportColumn = useMemo(() => {
    return (
      props.django?.user?.role_types?.find((role: string) =>
        ["TECHNICIAN", "REGISTERED_NURSE"].includes(role)
      ) && CONFIG.SPECIMEN_MANAGEMENT_LAB_REPORT
    );
  }, [props.django]);

  const showLabReportPdfColumn = useMemo(() => {
    return props.django?.user?.role_types?.find((role: string) =>
      ["REGISTERED_NURSE"].includes(role)
    );
  }, [props.django]);

  const labOrderQueue = useMemo(() => {
    return (props?.labOrderQueue?.labOrderQueueList || [])
      .sort((a: any, b: any) => (a.highest_urgency <= b.highest_urgency ? 1 : -1))
      .map((item: any, index: number) => {
        const disabledLabReport = item.status_name !== "ได้รับผล Lab";

        return {
          ...item,
          check: (
            <Checkbox
              // value={item.id}
              checked={checkedIds.includes(item.id)}
              onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                console.log(" handleCheckedSpecimenTube ");
                handleCheckedSpecimenTube(item.id, data.checked);
                event.stopPropagation();
              }}
            ></Checkbox>
          ),

          labId: item.full_access_number,
          hn:
            item.encounter_type === "IPD" && item.encounter_an
              ? `${item.hn}/${item.encounter_an}`
              : item.hn,
          arrive_time: formatDatetime(item.arrive_time_iso, true),
          status_name: (
            <div>
              {item.status_name}
              {item.order_item_have_rejected && <Label color="red">REJECT</Label>}
            </div>
          ),
          doctor_name: (
            <div style={{ display: "flex" }}>
              <div style={{ width: "80%" }}>{item.order_by_name}</div>
              <div style={{ width: "20%" }}>
                {item.student && item.need_approve ? (
                  <img src="/static/images/drugOrder/need_approve.png" alt="need approve" />
                ) : (
                  item.student &&
                  !item.need_approve && (
                    <Popup
                      content={
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              color: "#898383",
                              fontSize: "12px",
                            }}
                          >
                            ตรวจสอบโดย
                          </div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            {item.approved_by_name}
                          </div>
                        </div>
                      }
                      on="hover"
                      pinned
                      position="bottom center"
                      trigger={
                        <img
                          src="/static/images/drugOrder/approve_check_out_line.png"
                          alt="approve check out line"
                        />
                      }
                    />
                  )
                )}
              </div>
            </div>
          ),
          lab_report: (
            <div style={{ display: "grid", placeContent: "center" }}>
              <img
                aria-hidden="true"
                src={IMAGES.edit}
                alt="paper-edit-outline"
                style={{ width: "23px", cursor: "pointer" }}
                onClick={handleOpenLabReport(item)}
              />
            </div>
          ),
          lab_report_pdf: (
            <div
              style={{ display: "grid", placeContent: "center", height: "100%" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <LoadingIcon
                color={disabledLabReport ? "grey" : "blue"}
                loading={
                  !!props.buttonLoadCheck?.[`${CARD_LAB_ORDER_QUEUE}_PRINT_LAB_RESULT_${index}`]
                }
                disabled={disabledLabReport}
                name="file pdf outline"
                style={{
                  fontSize: "1.35rem",
                  cursor: disabledLabReport ? "unset" : "pointer",
                }}
                onClick={handlePrintLabResult(item, index)}
              />
            </div>
          ),
        };
      });
  }, [props.labOrderQueue?.labOrderQueueList, checkedIds, props.buttonLoadCheck]);

  console.log("labOrderQueue: ", labOrderQueue);
  console.log("checkedIds: ", checkedIds);

  const divisionOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "แสดงทั้งหมด" },
      ...(props.masterOptions?.division || []),
    ].map((item: any) => ({
      key: item.key,
      value: item.value,
      text: item.text,
    }));
  }, [props.masterOptions?.division]);

  const doctorOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "แสดงทั้งหมด" },
      ...(props.masterOptions?.doctor || []),
    ].map((item: any) => ({
      key: item.key,
      value: item.value,
      text: item.text,
    }));
  }, [props.masterOptions?.doctor]);

  const headerTitle = useMemo(() => {
    let title =
      props.size === "max"
        ? ",วันเวลาสั่ง,Lab ID,HN/AN,EN type,ชื่อ-นามสกุล,แผนกที่สั่ง,แพทย์ผู้สั่ง,ประเภทของแลป,ความเร่งด่วน,วันเวลาที่รับเข้า,สถานะ"
        : "HN,ชื่อ-สกุล,แพทย์,สถานะ";

    if (showLabReportColumn || showLabReportPdfColumn) {
      title += ",Lab Report";
    }

    return title;
  }, [props.size, showLabReportColumn, showLabReportPdfColumn]);

  const headerKey = useMemo(() => {
    let key =
      props.size === "max"
        ? "check,date,labId,hn,encounter_type,patient_name,division_name,doctor_name,lab_type_label,highest_urgency,arrive_time,status_name"
        : "hn,patient_name,doctor_name,status_name";

    if (showLabReportColumn) {
      key += ",lab_report";
    } else if (showLabReportPdfColumn) {
      key += ",lab_report_pdf";
    }

    return key;
  }, [props.size, showLabReportColumn, showLabReportPdfColumn]);

  const headerWidth = useMemo(() => {
    let width =
      props.size === "max"
        ? "30,^60,^60,^100,^50,^100,^100,^100,^60,^60,^70,^70"
        : "";

    if (showLabReportColumn || showLabReportPdfColumn) {
      width += ",80";
    }

    return width;
  }, [props.size, showLabReportColumn, showLabReportPdfColumn]);

  const labCrossMatchOrderQueue = useMemo(() => {
    const sortItems = (
      props?.labOrderQueue?.labCrossMatchOrderQueue || []
    ).sort((a: any, b: any) =>
      a.highest_urgency <= b.highest_urgency ? 1 : -1
    );

    return sortItems.map((item: any) => ({
      ...item,
      check: <Checkbox />,
      crossMatchDate: item.perform_date
        ? adToBe(item.perform_date, "YYYY-MM-DD")
        : "",
      labId: item.full_access_number,
      status_name: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            color: item.highest_urgency === "STAT" ? "red" : "",
            alignItems: "center",
          }}
        >
          <div>{item.status_name}</div>
          {item.order_status_name === "APPOINTMENT" && (
            <div>{"(รอเปิด Encounter)"}</div>
          )}
          {item.order_item_have_rejected && <Label color="red">REJECT</Label>}
        </div>
      ),
    }));
  }, [props?.labOrderQueue?.labCrossMatchOrderQueue]);

  // Handler
  const handleChangeValue = (key: string) => (e: any, v: any) => {
    props.setProp(`labOrderQueue.${key}`, v.value);
  };

  const handleChangeChecked =
    (key: string) =>
    (e: any, { checked }: any) => {
      let params: any = {};

      if (key === "checkSpecimenReject" && checked) {
        params["selectedStatus"] = "";
      }
      params[key] = checked;
      props.onEvent({ message: "SetLabOrderQueueData", params: params });
    };

  const handleChangeDate = (key: string) => (date: any) => {
    props.setProp(`labOrderQueue.${key}`, date);
  };

  const handleSearch = () => {
    props.onEvent({
      message: "GetLabOrderQueue",
      params: { action: "search" },
    });
  };

  const handleShowCrossMatch = () => {
    if (!props.labOrderQueue?.showCrossMatch) {
      props.onEvent({
        message: "GetLabOrderQueue",
        params: {
          action: "CROSS_MATCH_SEARCH",
          card: CARD_LAB_ORDER_QUEUE,
        },
      });
    }
    props.setProp(
      `labOrderQueue.showCrossMatch`,
      !props.labOrderQueue?.showCrossMatch
    );
  };

  const handleCloseModLabReport = async (changed?: boolean) => {
    await props.setProp(`labOrderQueue.openLabReport`, false);

    props.setProp("selectedPatient", null);

    if (changed) {
      handleSearch();
    }
  };

  // console.log("CardLabOrderQueue props: ", props)
  return (
    <div
      style={{
        padding: props.size === "max" ? "15px" : "0px",
        ...(props.style || {}),
        paddingBottom: "1.5rem",
        width: "calc(100vw - 60px)",
      }}
    >
      <CardLabOrderQueueUX
        // options
        divisionOptions={divisionOptions}
        doctorOptions={doctorOptions}
        encounterOptions={ENCOUNTER_OPTIONS}
        labOptions={LAB_OPTIONS}
        statusOptions={STATUS_OPTIONS}
        urgencyOptions={URGENCY_OPTIONS}
        approveOption={approveOption}
        // data
        labOrderQueue={labOrderQueue}
        selectedBarcode={props.labOrderQueue?.selectedBarcode}
        selectedHN={props.labOrderQueue?.selectedHN}
        selectedFirstname={props.labOrderQueue?.selectedFirstname}
        selectedLastname={props.labOrderQueue?.selectedLastname}
        selectedStartDate={props.labOrderQueue?.selectedStartDate}
        selectedEndDate={props.labOrderQueue?.selectedEndDate}
        selectedEncounterType={props.labOrderQueue?.selectedEncounterType}
        selectedStatus={props.labOrderQueue?.selectedStatus}
        selectedLabType={props.labOrderQueue?.selectedLabType}
        selectedUrgency={props.labOrderQueue?.selectedUrgency}
        selectedDivision={props.labOrderQueue?.selectedDivision}
        selectedDoctor={props.labOrderQueue?.selectedDoctor}
        selectedApprove={props.labOrderQueue?.selectedApprove}
        checkSpecimen={props.labOrderQueue?.checkSpecimen}
        checkSpecimenReject={props.labOrderQueue?.checkSpecimenReject}
        tableHeight={props.tableHeight}
        headerTitle={headerTitle}
        headerKey={headerKey}
        width={headerWidth}
        // cross match section
        selectedStartDateCrossMatch={
          props.labOrderQueue?.selectedStartDateCrossMatch
        }
        selectedEndDateCrossMatch={
          props.labOrderQueue?.selectedEndDateCrossMatch
        }
        headerTitleCrossMatch={
          props.size === "max"
            ? "วันที่ทำ Cross-Match,Lab ID,HN/AN,ชื่อ-นามสกุล,แผนกที่สั่ง,แพทย์ผู้สั่ง,ความเร่งด่วน,วันเวลาที่รับเข้า,สถานะ"
            : "HN,ชื่อ-สกุล,แพทย์,สถานะ"
        }
        headerKeyCrossMatch={
          props.size === "max"
            ? "crossMatchDate,labId,hn,patient_name,division_name,doctor_name,highest_urgency,arrive_time,status_name"
            : "hn,patient_name,doctor_name,status_name"
        }
        widthCrossMatch={
          props.size === "max" ? "^70,^70,^70,^70,^70,^70,^70,^70,^70" : ""
        }
        labCrossMatchOrderQueue={labCrossMatchOrderQueue}
        // Config
        disableBloodBook={CONFIG.RAKSTHAI_HIDE_LAB_BLOODBOOK}
        disabledDivFilter={CONFIG.SPECIMEN_MANAGEMENT_DISABLED_DIV_FILTER}
        isShow={props.labOrderQueue?.showCrossMatch}
        // Callback
        handleChangeValue={handleChangeValue}
        handleChangeDate={handleChangeDate}
        handleChangeChecked={handleChangeChecked}
        rowPropsCrossMatch={handleRowPropsCrossMatch}
        onClickShowCrossMatch={handleShowCrossMatch}
        columnProps={handleColumnProps}
        onSearch={handleSearch}
        // Element
        StartDate={
          <DateTextBox
            value={props.labOrderQueue?.selectedStartDate || ""}
            onChange={(date: string) =>
              props.setProp(`labOrderQueue.selectedStartDate`, date)
            }
          />
        }
        EndDate={
          <DateTextBox
            value={props.labOrderQueue?.selectedEndDate || ""}
            onChange={(date: string) =>
              props.setProp(`labOrderQueue.selectedEndDate`, date)
            }
          />
        }
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.onEvent({
                message: "GetLabOrderQueue",
                params: {
                  action: "search",
                  card: CARD_LAB_ORDER_QUEUE,
                  buttonLoadKey: `${CARD_LAB_ORDER_QUEUE}_SEARCH`,
                },
              });
            }}
            // data
            paramKey={`${CARD_LAB_ORDER_QUEUE}_SEARCH`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_LAB_ORDER_QUEUE}_SEARCH`]
            }
            // config
            style={{ width: "100%", height: "100%" }}
            name="SEARCH"
            title={intl.formatMessage({ id: "ค้นหา" })}
            color="blue"
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.onEvent({
                message: "GetLabOrderQueue",
                params: {
                  action: "PRINT",
                  card: CARD_LAB_ORDER_QUEUE,
                  buttonLoadKey: `${CARD_LAB_ORDER_QUEUE}_PRINT`,
                  ids: checkedIds,
                },
              });
            }}
            // data
            paramKey={`${CARD_LAB_ORDER_QUEUE}_PRINT`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_LAB_ORDER_QUEUE}_PRINT`]
            }
            // config
            color="violet"
            size="small"
            title={intl.formatMessage({ id: "ใบ Lab Request" })}
          />
        }
        crossMatchSearchButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.onEvent({
                message: "GetLabOrderQueue",
                params: {
                  action: "CROSS_MATCH_SEARCH",
                  card: CARD_LAB_ORDER_QUEUE,
                  buttonLoadKey: `${CARD_LAB_ORDER_QUEUE}_CROSS_MATCH_SEARCH`,
                },
              });
            }}
            // data
            paramKey={`${CARD_LAB_ORDER_QUEUE}_CROSS_MATCH_SEARCH`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_LAB_ORDER_QUEUE}_CROSS_MATCH_SEARCH`
              ]
            }
            // config
            style={{ width: "100%", height: "100%" }}
            name="CROSS_MATCH_SEARCH"
            title={intl.formatMessage({ id: "ค้นหา" })}
            color="blue"
          />
        }
        crossMatchPrintButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.onEvent({
                message: "GetLabOrderQueue",
                params: {
                  action: "CROSS_MATCH_PRINT",
                  card: CARD_LAB_ORDER_QUEUE,
                  buttonLoadKey: `${CARD_LAB_ORDER_QUEUE}_CROSS_MATCH_PRINT`,
                  // ids: checkedIds,
                },
              });
            }}
            // data
            paramKey={`${CARD_LAB_ORDER_QUEUE}_CROSS_MATCH_PRINT`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_LAB_ORDER_QUEUE}_CROSS_MATCH_PRINT`
              ]
            }
            // config
            color="blue"
            size="small"
            title={
              <div>
                พิมพ์รายงาน Cross Match <Icon name="print" />
              </div>
            }
          />
        }
        languageUX={props.languageUX}
      />

      <Modal
        open={!!props.labOrderQueue?.openLabReport}
        // style
        style={{ width: "auto" }}
      >
        <CardLabReport
          setProp={props.setProp}
          onEvent={props.onEvent}
          // seq
          runSequence={props.runSequence}
          LabReportSequence={props.LabReportSequence}
          // data
          titleName={"Lab Report"}
          patientInfo={props.selectedPatient}
          selectedLabOrderWorking={props.selectedLabOrderWorking}
          permissionLabResultConfidential={
            props.permissionLabResultConfidential
          }
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          searchedItemListWithKey={props.searchedItemListWithKey}
          //  options
          masterOptions={props.masterOptions}
          // config
          showPatientInfo={true}
          // callback
          onClose={handleCloseModLabReport}
          languageUX={props.languageUX}
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardLabOrderQueue);
