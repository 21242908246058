import React, { useState, useEffect, useMemo, useCallback, useImperativeHandle } from "react";
import { Button, Checkbox, Form, Icon, Input, Label, Modal } from "semantic-ui-react";

// UX
import CardSpecimenCollectorUX from "./CardSpecimenCollectorUX";
import CardSpecimenLogUX from "./CardSpecimenLogUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModLockExpense from "react-lib/apps/HISV3/BIL/ModLockExpense";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import PasswordRecheck from "react-lib/apps/common/PasswordRecheck";
import { useIntl } from "react-intl";

const SC_STATUS = {
  ORDERED: "ORDERED",
  SPECIMEN_COLLECTING: "SPECIMEN_COLLECTING",
  SPECIMEN_COLLECTED: "SPECIMEN_COLLECTED",
  SPECIMEN_SENT: "SPECIMEN_SENT",
  SPECIMEN_CHECKED_OUT: "SPECIMEN_CHECKED_OUT",
  PROCESSING: "PROCESSING",
  FINISHED: "FINISHED",
  REPORTED: "REPORTED",
  CANCELED: "CANCELED",
  SPECIMEN_REJECTED: "SPECIMEN_REJECTED",
  PENDING_CONFIRM: "PENDING_CONFIRM",
};

const SPECIMEN_COLLECTOR_STATUS_COLOR = {
  [SC_STATUS.ORDERED]: "grey",
  [SC_STATUS.SPECIMEN_COLLECTING]: "olive",
  [SC_STATUS.SPECIMEN_COLLECTED]: "blue",
  [SC_STATUS.SPECIMEN_SENT]: "pink",
  [SC_STATUS.SPECIMEN_CHECKED_OUT]: "brown",
  [SC_STATUS.PROCESSING]: "yellow",
  [SC_STATUS.FINISHED]: "purple",
  [SC_STATUS.REPORTED]: "violet",
  [SC_STATUS.CANCELED]: "black",
  [SC_STATUS.SPECIMEN_REJECTED]: "red",
  [SC_STATUS.PENDING_CONFIRM]: "green",
} as const;

const CARD_KEY = "CardSpecimenCollector";

const CardSpecimenCollector = React.forwardRef<any, any>((props, ref) => {
  const intl = useIntl();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [openLog, setOpenLog] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState<"STICKER" | "ROBO" | "">("");
  const [print, setPrint] = useState<"STICKER" | "ROBO" | "">("");
  const [loadingCollect, setLoadingCollect] = useState(false);
  const [collect, setCollect] = useState(false);
  const [filterData, setFilterData] = useState<any>({
    isLabDivision: false,
    labDivision: "",
    isStatus: false,
    status: "",
    isFinished: false,
  });
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [modRejectData, setModRejectData] = useState<any>({
    open: false,
    note: "",
  });

  useImperativeHandle(ref, () => ({
    specimenList: specimenFilter,
  }));

  // Use Effect
  useEffect(() => {
    props.onEvent({ message: "SpecimenCollectorMasterData" });
  }, []);

  useEffect(() => {
    if (
      props.errorMessage?.OrderCentralLab !== null &&
      props.errorMessage?.OrderCentralLab !== undefined
    ) {
      setLoadingCollect(false);
      props.onEvent({
        message: "EndCollectSpecimen",
        params: { action: "clearMessage" },
      });
      // #toast.error("บันทึกไม่สำเร็จ");
    }
    if (
      props.successMessage?.OrderCentralLab !== null &&
      props.successMessage?.OrderCentralLab !== undefined
    ) {
      setLoadingCollect(false);
      setCollect(true);
      setTimeout(() => {
        setCollect(false);
      }, 3000);
      props.onEvent({
        message: "EndCollectSpecimen",
        params: { action: "clearMessage" },
      });
      // #toast.success("บันทึกสำเร็จ");
    }
  }, [props.successMessage?.OrderCentralLab, props.errorMessage?.OrderCentralLab]);

  useEffect(() => {
    if (
      props.errorMessage?.PrintLabSticker !== null &&
      props.errorMessage?.PrintLabSticker !== undefined
    ) {
      setLoadingPrint("");
      // #props.onEvent({
      //   message: "PrintLabSticker",
      //   params: { action: "clearMessage" },
      // });
      // toast.error("พิมพ์ไม่สำเร็จ");
    }

    if (
      props.successMessage?.PrintLabSticker !== null &&
      props.successMessage?.PrintLabSticker !== undefined
    ) {
      setPrint(loadingPrint);
      setLoadingPrint("");

      setTimeout(() => {
        setPrint("");
      }, 2000);

      props.onEvent({
        message: "PrintLabSticker",
        params: { action: "clearMessage" },
      });
      // #toast.success("พิมพ์สำเร็จ");
    }
  }, [props.successMessage?.PrintLabSticker, props.errorMessage?.PrintLabSticker, loadingPrint]);

  useEffect(() => {
    props.setProp("specimenCollectorMasterData.specimenTube", []);
  }, [props.selectedLabOrderWorking?.specimen_containers]);

  // ----------
  const specimenFilter = useMemo(() => {
    const result: any[] = [];

    if (props.selectedLabOrderWorking?.specimen_containers || isSearch) {
      props.selectedLabOrderWorking?.specimen_containers?.forEach((item: any, idx: any) => {
        if (filterData.isLabDivision) {
          if (item.lab_division !== filterData.labDivision) {
            return;
          }
        }
        if (filterData.isStatus) {
          if (item.status !== filterData.status) {
            return;
          }
        }
        if (filterData.isFinished) {
          if (item.status !== SC_STATUS.SPECIMEN_COLLECTED) {
            return;
          }
        }
        result.push({ ...item, idx });
      });

      setIsSearch(false);
    }

    return result;
  }, [props.selectedLabOrderWorking?.specimen_containers, isSearch]);

  // ----------

  // Use Callback
  const handleCheckAll = useCallback(
    (check: any) => {
      let data: any[] = [];
      let items: any[] = [...specimenFilter];

      if (check) {
        items.forEach((item: any) => {
          data.push(item);
        });
      }

      props.setProp("specimenCollectorMasterData.specimenTube", data);
    },
    [specimenFilter]
  );

  const handleCheckedSpecimenTube = useCallback(
    (idx: any) => {
      let items: any[] = props.selectedLabOrderWorking?.specimen_containers;
      let specimenTube: any[] = [...(props.specimenCollectorMasterData?.specimenTube || [])];

      const index = specimenTube.findIndex((item: any) => item.pk === items[idx]?.pk);

      if (index !== -1) {
        specimenTube.splice(index, 1);
      } else {
        specimenTube.push(items[idx]);
      }

      props.setProp("selectedContainer", items[idx]);

      props.setProp("specimenCollectorMasterData.specimenTube", specimenTube);
    },
    [
      props.selectedLabOrderWorking?.specimen_containers,
      props.specimenCollectorMasterData?.specimenTube,
    ]
  );

  const handleSpecimenLog = useCallback(
    (item: any) => () => {
      const items: any[] = props.selectedLabOrderWorking?.specimen_containers;

      props.setProp("selectedContainer", items[item.idx]);

      props.onEvent({
        message: "SpecimenLogList",
        params: {
          id: items[item.idx].id || null,
        },
      });

      setOpenLog(true);
    },
    [props.selectedLabOrderWorking?.specimen_containers]
  );

  const handleCancelSpecimen = useCallback(
    (item: any) => () => {
      const items: any[] = props.selectedLabOrderWorking?.specimen_containers;

      props.onEvent({
        message: "CancelCollectSpecimen",
        params: {
          note: "note",
          containerId: items[item.idx].id,
          containerOrder: items[item.idx].order,
          cardKey: CARD_KEY,
        },
      });
    },
    [props.selectedLabOrderWorking?.specimen_containers]
  );

  // Use Memo
  const specimenItems = useMemo(() => {
    const specimenTubeIds: any[] = (props.specimenCollectorMasterData?.specimenTube || []).map(
      (item: any) => item.pk
    );

    return specimenFilter.map((item: any, index: number) => ({
      ...item,
      containerColor: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* <Label circular style={{ color: `${item.color}` }}></Label> */}
          <div
            style={{
              backgroundColor: `${item.color}`,
              borderRadius: "50%",
              width: "1.5em",
              height: "1.5em",
            }}
          ></div>
        </div>
      ),
      stickerBarcode: <>{item.is_printed ? item.barcode : ""}</>,
      statusLabel: (
        <label
          style={{
            color: (SPECIMEN_COLLECTOR_STATUS_COLOR as any)[
              item.status_name === SC_STATUS.REPORTED && !item.is_authorized
                ? SC_STATUS.PENDING_CONFIRM
                : item.status_name
            ],
          }}
        >
          {item.status_name === SC_STATUS.REPORTED && !item.is_authorized
            ? intl.formatMessage({id: "รอยืนยัน"})
            : item.status_label}
        </label>
      ),
      receivedLabel: (
        <>
          {item.received === null ? (
            <label>-</label>
          ) : ![SC_STATUS.ORDERED, SC_STATUS.SPECIMEN_COLLECTING].includes(item.status) ? (
            <Label circular color="green">
              Y
            </Label>
          ) : (
            <Label circular color="orange">
              N
            </Label>
          )}
        </>
      ),
      action: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button id={`CardSpecimenCollector-Button-History-${index}`} icon size="mini" style={{ padding: "5px" }} onClick={handleSpecimenLog(item)}>
            <Icon name="history" />
          </Button>
          {![
            SC_STATUS.SPECIMEN_CHECKED_OUT,
            SC_STATUS.SPECIMEN_COLLECTED,
            SC_STATUS.SPECIMEN_REJECTED,
            SC_STATUS.REPORTED,
            SC_STATUS.ORDERED, // Issue 68721
          ].includes(item.status_name) && (
            <Button
            id={`CardSpecimenCollector-Button-Cancel-${index}`}
              color="red"
              size="mini"
              disabled={props.readOnly}
              style={{ padding: "5px 10px" }}
              onClick={handleCancelSpecimen(item)}
            >
              Cancel
            </Button>
          )}
        </div>
      ),
      check: (
        <Checkbox
          id={`CardSpecimenCollector-Checkbox-Selected-${index}`}
          checked={specimenTubeIds.includes(item.pk)}
          disabled={props.readOnly}
          onChange={(_e: any) => {
            handleCheckedSpecimenTube(item.idx);
          }}
        ></Checkbox>
      ),
    }));
  }, [props.specimenCollectorMasterData?.specimenTube, specimenFilter, props.readOnly]);

  const isCheckAll = useMemo(() => {
    const specimenTube: any[] = props.specimenCollectorMasterData?.specimenTube || [];
    return !!specimenTube.length && specimenTube.length === specimenItems.length;
  }, [props.specimenCollectorMasterData?.specimenTube, specimenItems]);

  const isCheckSomeItem = useMemo(() => {
    return (props.specimenCollectorMasterData?.specimenTube || []).length > 0;
  }, [props.specimenCollectorMasterData?.specimenTube, specimenItems]);

  const isAllowReject = useMemo(() => {
    return (
      specimenItems.some((item) =>
        [SC_STATUS.SPECIMEN_SENT, SC_STATUS.SPECIMEN_CHECKED_OUT].includes(item.status)
      ) &&
      !!specimenItems.length &&
      props.selectedDivision?.type_label === "แผนกเก็บ Specimen"
    );
  }, [specimenItems, props.selectedDivision]);

  const isAllowReceive = useMemo(() => {
    return (
      specimenItems.some((item) => [SC_STATUS.SPECIMEN_COLLECTED].includes(item.status)) &&
      !!specimenItems.length &&
      props.selectedDivision?.type_label === "แผนกเก็บ Specimen"
    );
  }, [specimenItems, props.selectedDivision]);

  const columnsSpecimenItems =
     [
      {
        Header: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              id="CardSpecimenCollector-Checkbox-All"
              checked={isCheckAll}
              disabled={props.readOnly}
              onChange={(_e: any, { checked }: any) => {
                handleCheckAll(checked);
              }}
            />
          </div>
        ),
        accessor: "check",
        width: 35,
      },
      {
        Header: "Specimen",
        accessor: "specimen_name",
        minWidth: 105,
      },
      {
        Header: "Container",
        accessor: "containerColor",
        minWidth: 75,
      },
      {
        Header: "Lab Test",
        accessor: "all_lab_test",
        minWidth: 115,
      },
      {
        Header: "การส่งตรวจ",
        accessor: "lab_type",
        minWidth: 75,
      },
      {
        Header: "Lab Division",
        accessor: "lab_division",
        minWidth: 105,
      },
      {
        Header: "Urgency",
        accessor: "urgency",
        minWidth: 80,
      },
      {
        Header: "Specimen Time",
        accessor: "specimen_time",
        minWidth: 90,
      },
      {
        Header: "Note",
        accessor: "items_note",
        minWidth: 50,
      },
      {
        Header: "สถานะ",
        accessor: "statusLabel",
        minWidth: 100,
      },
      {
        Header: "ได้รับ",
        accessor: "receivedLabel",
        minWidth: 50,
      },
      {
        Header: "สติ๊กเกอร์",
        accessor: "stickerBarcode",
        minWidth: 110,
      },
      {
        Header: "Action",
        accessor: "action",
        minWidth: 100,
      },
    ]


  const specimenLogs = useMemo(
    () =>
      props.specimenLogs?.map((item: any, _idx: any) => {
        return {
          ...item,
          datetime: formatDatetime(item.datetime),
        };
      }),
    [props.specimenLogs]
  );
  // Table
  const handleRowProps = (_state: any, rowInfo: any) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id && rowInfo?.original?.id === props.selectedContainer?.id
            ? "#cccccc"
            : "white",
      },
      // onClick: () => {
      //   props.setProp("selectedContainer", rowInfo?.original);
      //   props.setProp(
      //     "specimenCollectorMasterData.specimenTube",
      //     rowInfo?.original
      //   );
      // },
    };
  };

  // Handler
  const handleFilterChange = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      setFilterData({ ...filterData, [data.name]: data.checked });
    } else {
      setFilterData({ ...filterData, [data.name]: data.value });
    }
  };

  const handleCloseModReject = () => {
    setModRejectData({ open: false, note: "" });
  };

  const handlePrintLabSummary = () => {
    props.onEvent({
      message: "HandlePrintLabSummary",
      params: { specimenItems },
    });
  };

  const handlePrintSticker = (isRobo: boolean) => (_e: any) => {
    props.onEvent({
      message: "PrintLabSticker",
      params: { action: "printLab", isRobo, cardKey: CARD_KEY },
    });

    setLoadingPrint(isRobo ? "ROBO" : "STICKER");
  };

  const handleCollect = async () => {
    if (props.selectedEncounter?.type === "IPD") {
      const isLocked = await props.onEvent({ message: "CheckEncounterLocker" });

      if (isLocked) {
        return;
      }
    }

    setLoadingCollect(true);

    props.onEvent({
      message: "EndCollectSpecimen",
      params: { password, action: "postCollect", cardKey: CARD_KEY },
    });

    setUsername("");
    setPassword("");
  };

  const handleReject = () => {
    const specimenTube: any[] = props.specimenCollectorMasterData?.specimenTube || [];
    const isOpenMod = specimenTube.every((item) =>
      [SC_STATUS.SPECIMEN_SENT, SC_STATUS.SPECIMEN_CHECKED_OUT].includes(item.status)
    );

    if (isOpenMod) {
      setModRejectData({ open: true, note: "" });
    } else {
      props.setProp(
        `errorMessage.${CARD_KEY}`,
        `ต้องมี Status เป็น "ห้อง Lab ได้รับ Specimen" จึงจะ reject ได้`
      );
    }
  };

  console.log("CardSpecimenCollector", props);

  return (
    <div style={{ paddingBottom: "2rem" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
        languageUX={props.languageUX}
      />

      <Modal
        closeIcon
        style={{ left: "unset !important" }}
        size={"large"}
        open={openLog}
        onClose={() => {
          setOpenLog(false);
        }}
      >
        <CardSpecimenLogUX
          setProp={props.setProp}
          onEvent={props.onEvent}
          specimenLogs={specimenLogs || []}
          languageUX={props.languageUX}
        />
      </Modal>

      <CardSpecimenCollectorUX
        // filter data
        specimenCollectorMasterData={props.specimenCollectorMasterData}
        filterData={filterData}
        // data
        containerList={specimenItems || []}
        columns_specimenItems={columnsSpecimenItems || []}
        // End collect specimen
        username={username}
        // password={password}
        loadingPrintSticker={loadingPrint === "STICKER"}
        loadingPrintRobo={loadingPrint === "ROBO"}
        loadingCollect={loadingCollect}
        // config
        // Print
        disablePrint={!isCheckSomeItem || props.readOnly}
        disabledPrintLabSummary={props.readOnly}
        disableInputUsername={props.readOnly}
        disableInputPassword={props.readOnly}
        // callback
        onPrintSticker={handlePrintSticker(false)}
        onPrintRobo={handlePrintSticker(true)}
        onPrintLabSummary={handlePrintLabSummary}
        rowProps={handleRowProps}
        onFilterChange={handleFilterChange}
        handleCollect={handleCollect}
        onSearch={() => setIsSearch(true)}
        changeUsername={(e: any) => setUsername(e.target.value)}
        // changePassword={(e: any) => setPassword(e.target.value)}
        // component
        print={print === "STICKER" ? <Icon name="check"></Icon> : "PRINT STICKER"}
        robo={print === "ROBO" ? <Icon name="check"></Icon> : "ROBO"}
        collect={collect ? <Icon name="check"></Icon> : "COLLECT"}
        passwordComponent={
          <PasswordRecheck
            password={password}
            setPassword={setPassword}
            placeholder="ระบุรหัสผ่าน"
          />

          // <Input
          //   disabled={props.readOnly || false}
          //   onChange={(e: any) => setPassword(e.target.value)}
          //   placeholder="ระบุรหัสผ่าน"
          //   size="mini"
          //   type="password"
          //   value={password}
          // ></Input>
        }
        buttonReceive={
          isAllowReceive && (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.onEvent({
                  message: "ReceiveSpecimen",
                  params: {
                    // username: username,
                    password: password,
                    card_key: CARD_KEY,
                  },
                });
                setUsername("");
                setPassword("");
              }}
              // data
              paramKey={`${CARD_KEY}_receive`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_receive`]}
              // config
              color="orange"
              disabled={
                props.django?.user?.role_types.includes("TECHNICIAN") ? !isCheckSomeItem : true
              }
              title="RECEIVED"
            />
          )
        }
        buttonReject={
          isAllowReject && (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleReject}
              // data
              paramKey={`${CARD_KEY}_reject`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_reject`]}
              // config
              color="red"
              disabled={
                props.django?.user?.role_types.includes("TECHNICIAN") ? !isCheckSomeItem : true
              }
              title="REJECT"
            />
          )
        }
        languageUX={props.languageUX}
      />

      <Modal open={modRejectData.open} onClose={handleCloseModReject} size="tiny">
        <Modal.Header>ต้องการ Reject หรือไม่</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group inline>
              <Form.Field width={5}>เหตุผลในการยกเลิก</Form.Field>
              <Form.Field width={11}>
                <Input
                  value={modRejectData.note}
                  onChange={(_event: any, data: any) => {
                    setModRejectData({ ...modRejectData, note: data.value });
                  }}
                ></Input>
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={() => {
              props.onEvent({
                message: "RejectSpecimen",
                params: {
                  // username: username,
                  password: password,
                  note: modRejectData.note,
                  card_key: CARD_KEY,
                },
              });
              setUsername("");
              setPassword("");
              handleCloseModReject();
            }}
          >
            Reject
          </Button>
          <Button color="red" onClick={handleCloseModReject}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>

      <ModLockExpense
        onEvent={props.onEvent}
        setProp={props.setProp}
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
      />
    </div>
  );
});

export default React.memo(CardSpecimenCollector);
