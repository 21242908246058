import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Input,
  Checkbox,
  Label,
  Dropdown,
  FormGroup,
  FormField,
  Button,
  Form
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardLabRequestUX = (props: any) => {
    return(
      <div>
        <div
          id="CardLabRequestUX-div-1"
          style={{padding: "10px 10px 15px", fontSize: "1.2rem", fontWeight: "bold" ,display: "flex", alignItems: "center" }}>
          Lab Request
          <div
            id="CardLabRequestUX-div-28"
            style={{ display: props.hidePreOrder ? "none" : "flex", alignItems: "center", marginLeft: "auto"}}>
            
            <Checkbox
              checked={props.isOrderTime || false}
              id="CardLabRequestUX-Checkbox-29"
              label={props.checkOutPerformDiv ? "คำสั่งนอกแผนก" : "สั่ง LAB ล่วงหน้า"}
              onChange={props.isAdvanceChange}
              style={{marginLeft: "15px", fontSize: "1rem", marginRight: props.checkOutPerformDiv ? "12px": ""}}>
            </Checkbox>
            <DateTextBox
              disabled={props.disableOrderTime}
              id="CardLabRequestUX-DateTextBox-31"
              minDate={props.minLabDate}
              onChange={props.orderTimeChange}
              style={{marginLeft: "15px", display: props.checkOutPerformDiv  ? "none" : ""}}
              value={props.orderTime}>
            </DateTextBox>
            <Dropdown
              disabled={props.disableOrderTime}
              id="CardLabRequestUX-Dropdown-35"
              onChange={props?.onChangeOutPerformDiv}
              options={props.divisionOpdOptions || []}
              search={true}
              selection={true}
              style={{display: props.checkOutPerformDiv  ? "" : "none"}}
              value={props?.outPerformDiv || ""}>
            </Dropdown>
          </div>
          <div
            id="CardLabRequestUX-div-37"
            style={{ display: props.isBloodBank ? "flex" : "none", alignItems: "center", marginLeft: "auto"}}>
            
            <Checkbox
              checked={props.appointmentDate === "beforeAppointment" }
              id="CardLabRequestUX-Checkbox-42"
              label={props.configAppointmentDateText}
              onChange={props.onCheckedBeforeAppointment}
              style={{marginLeft: "15px", fontSize: "1rem", marginRight:  "12px"}}>
            </Checkbox>
            <label
              id="CardLabRequestUX-label-43"
              style={{marginLeft: "15px", fontSize: "1rem", marginRight:  "12px"}}>
              แผนก
            </label>
            <Dropdown
              disabled={props.appointmentDate !== "beforeAppointment" }
              id="CardLabRequestUX-Dropdown-40"
              onChange={props?.onChangePerformDiv}
              options={props.divisionOpdOptions || []}
              search={true}
              selection={true}
              style={{display: props.appointmentDate === "beforeAppointment"  ? "" : ""}}
              value={props?.performDiv || ""}>
            </Dropdown>
          </div>
        </div>
        <div
          id="CardLabRequestUX-div-36"
          style={{width: "100%"}}>
          {props.ErrorMessage}
        </div>
        <div
          className="ui form"
          id="CardLabRequestUX-div-2">
          
          <FormGroup
            id="CardLabRequestUX-FormGroup-64"
            inline={true}
            widths={16}>
            <FormField
              id="CardLabRequestUX-FormField-65"
              inline={true}
              width={5}>
              <label
                id="CardLabRequestUX-label-70"
                style={{minWidth: "max-content"}}>
                Lab Division
              </label>
              <Dropdown
                defaultValue="All Division"
                fluid={true}
                id="CardLabRequestUX-Dropdown-77"
                onChange={props.changeLabDivision}
                options={props.labDivisions}
                search={true}
                selection={true}
                style={{width:"100%"}}
                value={props.labDivision}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardLabRequestUX-FormField-66"
              inline={true}
              width={3}>
              <label
                id="CardLabRequestUX-label-71"
                style={{minWidth: "max-content"}}>
                รหัสบริการ
              </label>
              <Input
                id="CardLabRequestUX-Input-75"
                onChange={props.changeLabCode}
                onKeyDown={(e: any) => { e.key === "Enter" && props.searchLabTest(); }}
                value={props.labCode}>
              </Input>
            </FormField>
            <FormField
              id="CardLabRequestUX-FormField-67"
              inline={true}
              width={4}>
              <label
                id="CardLabRequestUX-label-72"
                style={{minWidth: "max-content"}}>
                ค้นหาชื่อ Lab
              </label>
              <Input
                id="CardLabRequestUX-Input-76"
                onChange={props.changeLabName}
                onKeyDown={(e: any) => { e.key === "Enter" && props.searchLabTest(); }}
                value={props.labName}>
              </Input>
            </FormField>
            <FormField
              id="CardLabRequestUX-FormField-78"
              style={{flex:1}}>
            </FormField>
            <FormField
              id="CardLabRequestUX-FormField-68"
              inline={true}>
              <div>
                {props.buttonSearch}
              </div>
            </FormField>
            <FormField
              id="CardLabRequestUX-FormField-69"
              inline={true}>
              <Button
                color="green"
                id="CardLabRequestUX-Button-74"
                onClick={props.openLabGroup}
                style={{display: props.isBloodBank? "none": "", minWidth: "max-content"}}>
                Lab ชุด
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <Table
          data={props.labTests}
          getTrProps={props.labTestRowProps}
          headers=",Code,Name,Specimen,Lab Division,การส่งตรวจ"
          id="tb-labList"
          keys="addButton,lab_code,name,specimen_name,lab_division_name,lab_type_label"
          minRows="5"
          showPagination={false}
          style={{height: "300px", marginTop: "0.5rem"}}>
        </Table>
        <div
          id="CardLabRequestUX-div-45"
          style={{padding: "15px 10px", fontSize: "1.2rem", fontWeight: "bold" ,display: "flex", alignItems: "center" }}>
          
          <div
            id="CardLabRequestUX-div-46">
            รายการคำสั่ง Lab
          </div>
          <div
            id="CardLabRequestUX-div-47"
            style={{ margin: "0px 15px" }}>
            {props.approveStatus}
          </div>
        </div>
        <Table
          data={props.labOrderItems}
          getTdProps={props.getTdPropsLabRequest}
          headers="Code,Name,Specimen,การส่งตรวจ,Specimen Time,Urgency,Note,Del"
          id="tb-labReq"
          keys="lab_code,name,specimen_name,lab_type_label,specimen_time,urgency,note,delete"
          minRows="5"
          showPagination={false}
          style={{height: "300px", margin: "0.5rem 0 1rem"}}
          widths="auto,auto,auto,auto,auto,auto,auto,60">
        </Table>
        <div
          id="CardLabRequestUX-div-12"
          style={{display: "flex", alignItems: "center"}}>
          
          <div
            id="CardLabRequestUX-div-13"
            style={{flex: 1.5, marginLeft: "10px", marginRight: "5px", minWidth: "max-content" }}>
            รายละเอียดเพิ่มเติม
          </div>
          <Input
            id="CardLabRequestUX-Input-14"
            onChange={props.onChangeMessage}
            style={{flex: 3, marginLeft: "10px", marginRight: "5px" }}
            value={props.message}>
          </Input>
          <div
            id="CardLabRequestUX-div-15"
            style={{flex: 0.5, marginLeft: "10px", marginRight: "5px", textAlign: "right" }}>
            ราคา
          </div>
          <Label
            className="large"
            id="CardLabRequestUX-Label-32"
            style={{flex: 1, marginLeft: "10px", marginRight: "5px", textAlign: "right", backgroundColor: "#dddddd" }}>
            {props.price_total}
          </Label>
          <div
            id="CardLabRequestUX-div-17"
            style={{flex: 0.5, marginLeft: "10px", marginRight: "5px", textAlign: "right", minWidth: "max-content" }}>
            เบิกได้
          </div>
          <Label
            className="large"
            id="CardLabRequestUX-Label-33"
            style={{flex: 1, marginLeft: "10px", marginRight: "5px", textAlign: "right", backgroundColor: "#dddddd" }}>
            {props.price_claimable}
          </Label>
          <div
            id="CardLabRequestUX-div-19"
            style={{flex: 0.7, marginLeft: "10px", marginRight: "5px" , textAlign: "right", minWidth: "max-content" }}>
            เบิกไม่ได้
          </div>
          <Label
            className="large"
            id="CardLabRequestUX-Label-34"
            style={{flex: 1, marginLeft: "10px", marginRight: "5px", textAlign: "right" , backgroundColor: "#dddddd" }}>
            {props.price_non_claimable}
          </Label>
        </div>
        <div
          id="CardLabRequestUX-div-23"
          style={{ display: "flow-root", paddingTop: "10px" }}>
          
          <div
            id="CardLabRequestUX-div-79"
            style={{ float: "left" }}>
            {props.orderByBox}
          </div>
          <div
            id="CardLabRequestUX-div-80"
            style={{ float: "right" }}>
            
            <Form
              id="CardLabRequestUX-Form-81">
              <FormGroup
                id="CardLabRequestUX-FormGroup-82">
                <FormField
                  id="CardLabRequestUX-FormField-83">
                  <div
                    id="CardLabRequestUX-div-88">
                    {props.buttonEditOrder}
                  </div>
                </FormField>
                <FormField
                  id="CardLabRequestUX-FormField-84"
                  style={{display: props.showCancelEdit && "none"}}>
                  <div
                    id="CardLabRequestUX-div-89">
                    {props.buttonSaveOrder}
                  </div>
                </FormField>
                <FormField
                  id="CardLabRequestUX-FormField-85">
                  <div
                    id="CardLabRequestUX-div-90">
                    {props.buttonConfirmOrder}
                  </div>
                </FormField>
                <FormField
                  id="CardLabRequestUX-FormField-86"
                  style={props.saveLabOrderStyle}>
                  <Button
                    color={props.isEdit ? "yellow" : "green"}
                    id="btn-saveLabReq"
                    loading={props.loadingSave}
                    onClick={props.saveLabOrder}>
                    {props.button_save}
                  </Button>
                </FormField>
                <FormField
                  id="CardLabRequestUX-FormField-87"
                  style={{display: props.showCancelEdit? "": "none"}}>
                  <Button
                    color="red"
                    id="btn-cancelLabReq"
                    onClick={props.onCancelEdit}>
                    CANCEL
                  </Button>
                </FormField>
              </FormGroup>
            </Form>
          </div>
        </div>
        <Table
          data={props.doctorLabOrderList}
          getTrProps={props.getTrPropsDoctorLabOrder}
          headers="Type,Detail,Careprovider,Del,Edit,Status"
          id="tb-labOrderStatus"
          keys="specific_type_name,detail,careprovider,delete,edit,order_status"
          minRows={6}
          showPagination={false}
          style={{marginBottom: "4rem", ...props.doctorLabOrderTableStyle}}
          widths="300,auto,200,100,100,200">
        </Table>
      </div>
    )
}


export default CardLabRequestUX

export const screenPropsDefault = {"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Thu Mar 06 2025 11:40:27 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Request"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 15px\", fontSize: \"1.2rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-2"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 10,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.labTests"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.labTestRowProps"
        },
        "headers": {
          "type": "value",
          "value": ",Code,Name,Specimen,Lab Division,การส่งตรวจ"
        },
        "id": {
          "type": "value",
          "value": "tb-labList"
        },
        "keys": {
          "type": "value",
          "value": "addButton,lab_code,name,specimen_name,lab_division_name,lab_type_label"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", marginTop: \"0.5rem\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 11,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.labOrderItems"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdPropsLabRequest"
        },
        "headers": {
          "type": "value",
          "value": "Code,Name,Specimen,การส่งตรวจ,Specimen Time,Urgency,Note,Del"
        },
        "id": {
          "type": "value",
          "value": "tb-labReq"
        },
        "keys": {
          "type": "value",
          "value": "lab_code,name,specimen_name,lab_type_label,specimen_time,urgency,note,delete"
        },
        "loading": {
          "type": "code",
          "value": ""
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", margin: \"0.5rem 0 1rem\"}"
        },
        "widths": {
          "type": "value",
          "value": "auto,auto,auto,auto,auto,auto,auto,60"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-12"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดเพิ่มเติม"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-13"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1.5, marginLeft: \"10px\", marginRight: \"5px\", minWidth: \"max-content\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Input",
      "parent": 12,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-Input-14"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeMessage"
        },
        "style": {
          "type": "code",
          "value": "{flex: 3, marginLeft: \"10px\", marginRight: \"5px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.message"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคา"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-15"
        },
        "style": {
          "type": "code",
          "value": "{flex: 0.5, marginLeft: \"10px\", marginRight: \"5px\", textAlign: \"right\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกได้"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-17"
        },
        "style": {
          "type": "code",
          "value": "{flex: 0.5, marginLeft: \"10px\", marginRight: \"5px\", textAlign: \"right\", minWidth: \"max-content\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-19"
        },
        "style": {
          "type": "code",
          "value": "{flex: 0.7, marginLeft: \"10px\", marginRight: \"5px\" , textAlign: \"right\", minWidth: \"max-content\" }"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-23"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flow-root\", paddingTop: \"10px\" }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 25,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.doctorLabOrderList"
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrPropsDoctorLabOrder"
        },
        "headers": {
          "type": "value",
          "value": "Type,Detail,Careprovider,Del,Edit,Status"
        },
        "id": {
          "type": "value",
          "value": "tb-labOrderStatus"
        },
        "keys": {
          "type": "value",
          "value": "specific_type_name,detail,careprovider,delete,edit,order_status"
        },
        "minRows": {
          "type": "code",
          "value": "6"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"4rem\", ...props.doctorLabOrderTableStyle}"
        },
        "widths": {
          "type": "value",
          "value": "300,auto,200,100,100,200"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-28"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.hidePreOrder ? \"none\" : \"flex\", alignItems: \"center\", marginLeft: \"auto\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Checkbox",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOrderTime || false"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-Checkbox-29"
        },
        "label": {
          "type": "code",
          "value": "props.checkOutPerformDiv ? \"คำสั่งนอกแผนก\" : \"สั่ง LAB ล่วงหน้า\""
        },
        "onChange": {
          "type": "code",
          "value": "props.isAdvanceChange"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", fontSize: \"1rem\", marginRight: props.checkOutPerformDiv ? \"12px\": \"\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 31,
      "name": "DateTextBox",
      "parent": 28,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disableOrderTime"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-DateTextBox-31"
        },
        "minDate": {
          "type": "code",
          "value": "props.minLabDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.orderTimeChange"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", display: props.checkOutPerformDiv  ? \"none\" : \"\"}"
        },
        "value": {
          "type": "code",
          "value": "props.orderTime"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Label",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.price_total"
        },
        "className": {
          "type": "value",
          "value": "large"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-Label-32"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, marginLeft: \"10px\", marginRight: \"5px\", textAlign: \"right\", backgroundColor: \"#dddddd\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Label",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.price_claimable"
        },
        "className": {
          "type": "value",
          "value": "large"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-Label-33"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, marginLeft: \"10px\", marginRight: \"5px\", textAlign: \"right\", backgroundColor: \"#dddddd\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Label",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.price_non_claimable"
        },
        "className": {
          "type": "value",
          "value": "large"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-Label-34"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, marginLeft: \"10px\", marginRight: \"5px\", textAlign: \"right\" , backgroundColor: \"#dddddd\" }"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Dropdown",
      "parent": 28,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disableOrderTime"
        },
        "fluid": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-Dropdown-35"
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangeOutPerformDiv"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOpdOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.checkOutPerformDiv  ? \"\" : \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.outPerformDiv || \"\""
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-36"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-37"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.isBloodBank ? \"flex\" : \"none\", alignItems: \"center\", marginLeft: \"auto\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Dropdown",
      "parent": 37,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.appointmentDate !== \"beforeAppointment\" "
        },
        "fluid": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-Dropdown-40"
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangePerformDiv"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOpdOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.appointmentDate === \"beforeAppointment\"  ? \"\" : \"\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.performDiv || \"\""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Checkbox",
      "parent": 37,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.appointmentDate === \"beforeAppointment\" "
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-Checkbox-42"
        },
        "label": {
          "type": "code",
          "value": "props.configAppointmentDateText"
        },
        "onChange": {
          "type": "code",
          "value": "props.onCheckedBeforeAppointment"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", fontSize: \"1rem\", marginRight:  \"12px\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-label-43"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", fontSize: \"1rem\", marginRight:  \"12px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-45"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"15px 10px\", fontSize: \"1.2rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการคำสั่ง Lab"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-46"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "code",
          "value": "props.approveStatus"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-47"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 15px\" }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-FormGroup-64"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 64,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-FormField-65"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 64,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-FormField-66"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 64,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-FormField-67"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 64,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-FormField-68"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 64,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-FormField-69"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Division"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-label-70"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสบริการ"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-label-71"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาชื่อ Lab"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-label-72"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Button",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab ชุด"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-Button-74"
        },
        "onClick": {
          "type": "code",
          "value": "props.openLabGroup"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isBloodBank? \"none\": \"\", minWidth: \"max-content\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Input",
      "parent": 66,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-Input-75"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeLabCode"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any) => { e.key === \"Enter\" && props.searchLabTest(); }"
        },
        "value": {
          "type": "code",
          "value": "props.labCode"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Input",
      "parent": 67,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-Input-76"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeLabName"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any) => { e.key === \"Enter\" && props.searchLabTest(); }"
        },
        "value": {
          "type": "code",
          "value": "props.labName"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Dropdown",
      "parent": 65,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": "All Division"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-Dropdown-77"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeLabDivision"
        },
        "options": {
          "type": "code",
          "value": "props.labDivisions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.labDivision"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 64,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-FormField-78"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderByBox"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-79"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"left\" }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-80"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Form",
      "parent": 80,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-Form-81"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormGroup",
      "parent": 81,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-FormGroup-82"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 82,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-FormField-83"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 82,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-FormField-84"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showCancelEdit && \"none\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 82,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-FormField-85"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "FormField",
      "parent": 82,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-FormField-86"
        },
        "style": {
          "type": "code",
          "value": "props.saveLabOrderStyle"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormField",
      "parent": 82,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-FormField-87"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showCancelEdit? \"\": \"none\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonEditOrder"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-88"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSaveOrder"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-89"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirmOrder"
        },
        "id": {
          "type": "value",
          "value": "CardLabRequestUX-div-90"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "Button",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "CANCEL"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "id": {
          "type": "value",
          "value": "btn-cancelLabReq"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelEdit"
        },
        "size": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Button",
      "parent": 86,
      "props": {
        "children": {
          "type": "code",
          "value": "props.button_save"
        },
        "color": {
          "type": "code",
          "value": "props.isEdit ? \"yellow\" : \"green\""
        },
        "id": {
          "type": "value",
          "value": "btn-saveLabReq"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingSave"
        },
        "onClick": {
          "type": "code",
          "value": "props.saveLabOrder"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 93,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardLabRequestUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
