import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  FormGroup,
  FormField,
  Input,
  Dropdown,
  Checkbox
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardLabOrderQueueUX = (props: any) => {
    return(
      <div
        id="CardLabOrderQueueUX-div-0"
        style={{ width: "100%",padding: "5px 0 0" }}>
        <div
          className="ui form"
          id="CardLabOrderQueueUX-div-2">

          <FormGroup
            id="CardLabOrderQueueUX-FormGroup-92">
            <FormField
              id="CardLabOrderQueueUX-FormField-93"
              width={2}>
              <div
                id="CardLabOrderQueueUX-div-101">
                Barcode
              </div>
              <Input
                fluid={true}
                icon="search"
                id="CardLabOrderQueueUX-Input-109"
                onChange={props.handleChangeValue("selectedBarcode")}
                placeholder="Lab ID, Container ID"
                style={{width: "100%"}}
                value={props.selectedBarcode}>
              </Input>
            </FormField>
            <FormField
              id="CardLabOrderQueueUX-FormField-94"
              width={2}>
              <div
                id="CardLabOrderQueueUX-div-102">
                HN
              </div>
              <Input
                fluid={true}
                icon="search"
                id="CardLabOrderQueueUX-Input-110"
                onChange={props.handleChangeValue("selectedHN")}
                style={{width: "100%"}}
                value={props.selectedHN}>
              </Input>
            </FormField>
            <FormField
              id="CardLabOrderQueueUX-FormField-95"
              width={2}>
              <div
                id="CardLabOrderQueueUX-div-103">
                ชื่อ
              </div>
              <Input
                fluid={true}
                id="CardLabOrderQueueUX-Input-111"
                onChange={props.handleChangeValue("selectedFirstname")}
                style={{width: "100%",}}
                value={props.selectedFirstname}>
              </Input>
            </FormField>
            <FormField
              id="CardLabOrderQueueUX-FormField-96"
              width={2}>
              <div
                id="CardLabOrderQueueUX-div-104">
                นามสกุล
              </div>
              <Input
                fluid={true}
                id="CardLabOrderQueueUX-Input-112"
                onChange={props.handleChangeValue("selectedLastname")}
                style={{width: "100%", }}
                value={props.selectedLastname}>
              </Input>
            </FormField>
            <FormField
              id="CardLabOrderQueueUX-FormField-97"
              width={2}>
              <label
                id="CardLabOrderQueueUX-label-150"
                style={{margin: 0}}>
                วันที่สั่ง
              </label>
              <div
                id="CardLabOrderQueueUX-div-113"
                style={{width: "100%"}}>
                {props.StartDate}
              </div>
            </FormField>
            <FormField
              id="CardLabOrderQueueUX-FormField-142">
              <label
                id="CardLabOrderQueueUX-label-146">
                {"\u00a0"}
              </label>
              <div
                id="CardLabOrderQueueUX-div-143"
                style={{marginTop: "0.55rem"}}>
                ถึง
              </div>
            </FormField>
            <FormField
              id="CardLabOrderQueueUX-FormField-98"
              width={2}>
              <label
                id="CardLabOrderQueueUX-label-151"
                style={{margin: 0}}>
                {"\u00a0"}
              </label>
              <div
                id="CardLabOrderQueueUX-div-114"
                style={{width: "100%"}}>
                {props.EndDate}
              </div>
            </FormField>
            <FormField
              id="CardLabOrderQueueUX-FormField-99"
              width={2}>
              <div
                id="CardLabOrderQueueUX-div-107">
                Encounter Type
              </div>
              <Dropdown
                defaultValue="ALL"
                fluid={true}
                id="CardLabOrderQueueUX-Dropdown-115"
                onChange={props.handleChangeValue("selectedEncounterType")}
                options={props.encounterOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedEncounterType}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardLabOrderQueueUX-FormField-100"
              width={2}>
              <div
                id="CardLabOrderQueueUX-div-108">
                Approve status
              </div>
              <Dropdown
                defaultValue="ALL"
                fluid={true}
                id="CardLabOrderQueueUX-Dropdown-116"
                onChange={props.handleChangeValue("selectedApprove")}
                options={props.approveOption}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedApprove}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardLabOrderQueueUX-FormGroup-117">
            <FormField
              id="CardLabOrderQueueUX-FormField-118">
              <label
                id="CardLabOrderQueueUX-label-147">
                {"\u00a0"}
              </label>
              <div
                id="CardLabOrderQueueUX-div-154"
                style={{width: "100%", height: "100%", display: "flex", alignItems: "center", marginTop: "-1.25rem"}}>

                <Checkbox
                  checked={props.checkSpecimen}
                  id="CardLabOrderQueueUX-Checkbox-156"
                  label="Specimen ที่ไม่ได้จัดเก็บ"
                  onChange={props.handleChangeChecked("checkSpecimen")}
                  style={{maxWidth: "max-content",width: "100%"}}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              id="CardLabOrderQueueUX-FormField-119">
              <label
                id="CardLabOrderQueueUX-label-148">
                {"\u00a0"}
              </label>
              <div
                id="CardLabOrderQueueUX-div-153"
                style={{width: "100%", height: "100%", display: "flex", alignItems: "center", marginTop: "-1.25rem"}}>

                <Checkbox
                  checked={props.checkSpecimenReject}
                  id="CardLabOrderQueueUX-Checkbox-155"
                  label="Specimen Rejection"
                  onChange={props.handleChangeChecked("checkSpecimenReject")}
                  style={{maxWidth: "max-content",width: "100%"}}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              id="CardLabOrderQueueUX-FormField-120"
              width={2}>
              <div
                id="CardLabOrderQueueUX-div-128">
                สถานะ
              </div>
              <Dropdown
                defaultValue="ALL"
                disabled={props.checkSpecimenReject}
                fluid={true}
                id="CardLabOrderQueueUX-Dropdown-135"
                onChange={props.handleChangeValue("selectedStatus")}
                options={props.statusOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedStatus}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardLabOrderQueueUX-FormField-121"
              width={2}>
              <div
                id="CardLabOrderQueueUX-div-129">
                ประเภทแลป
              </div>
              <Dropdown
                defaultValue="ALL"
                fluid={true}
                id="CardLabOrderQueueUX-Dropdown-136"
                onChange={props.handleChangeValue("selectedLabType")}
                options={props.labOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedLabType}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardLabOrderQueueUX-FormField-122"
              width={2}>
              <div
                id="CardLabOrderQueueUX-div-130">
                ความเร่งด่วน
              </div>
              <Dropdown
                defaultValue="ALL"
                fluid={true}
                id="CardLabOrderQueueUX-Dropdown-137"
                onChange={props.handleChangeValue("selectedUrgency")}
                options={props.urgencyOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedUrgency}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardLabOrderQueueUX-FormField-123"
              width={2}>
              <div
                id="CardLabOrderQueueUX-div-131">
                แผนกที่สั่ง
              </div>
              <Dropdown
                defaultValue="ALL"
                disabled={props.disabledDivFilter|| false}
                fluid={true}
                id="CardLabOrderQueueUX-Dropdown-138"
                onChange={props.handleChangeValue("selectedDivision")}
                options={props.divisionOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedDivision}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardLabOrderQueueUX-FormField-124"
              width={2}>
              <div
                id="CardLabOrderQueueUX-div-132">
                แพทย์ผู้สั่ง
              </div>
              <Dropdown
                defaultValue="ALL"
                fluid={true}
                id="CardLabOrderQueueUX-Dropdown-139"
                onChange={props.handleChangeValue("selectedDoctor")}
                options={props.doctorOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedDoctor}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardLabOrderQueueUX-FormField-125"
              width={2}>
              <label
                id="CardLabOrderQueueUX-label-152">
                {"\u00a0"}
              </label>
              <div
                id="CardLabOrderQueueUX-div-141">
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
          <div
            id="CardLabOrderQueueUX-div-48"
            style={{display: "flex", paddingBottom: "5px"}}>

            <div
              id="CardLabOrderQueueUX-div-51"
              style={{display: "flex", flex: "1", alignItems: "center"}}>

              <div
                id="CardLabOrderQueueUX-div-52"
                style={{width: "auto", padding: "0px 5px",...(props.disableBloodBook && {display: "none"})}}>

                <Button
                  color={props.isShow? "pink": "green"}
                  id="CardLabOrderQueueUX-Button-56"
                  onClick={props.onClickShowCrossMatch}>
                  {props.isShow? "ปิดรายการจองเลือด":"แสดงรายการจองเลือด"}
                </Button>
              </div>
            </div>
            <div
              id="CardLabOrderQueueUX-div-49"
              style={{textAlign: "right"}}>

              <div
                id="CardLabOrderQueueUX-div-88">
                {props.buttonPrint}
              </div>
            </div>
          </div>
        </div>
        <Table
          className="--rt-tbody-overflow-y-hidden"
          data={props.labOrderQueue}
          getTdProps={props.columnProps}
          getTrProps={props.rowProps}
          headers={props.headerTitle}
          id="CardLabOrderQueueUX-Table-1"
          keys={props.headerKey}
          minRows="12"
          showPagination={false}
          style={{ height: props.isShow?"44vh":props.tableHeight }}
          widths={props.width}>
        </Table>
        <div
          id="CardLabOrderQueueUX-div-62"
          style={{ display: props.isShow? "flex": "none", flexDirection: "column"}}>

          <div
            id="CardLabOrderQueueUX-div-75"
            style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>

            <div
              id="CardLabOrderQueueUX-div-76"
              style={{padding: "10px 5px", display: "flex", alignItems: "center"}}>
              {}
              <div
                id="CardLabOrderQueueUX-div-78"
                style={{display: "flex", flex: "1", alignItems: "center"}}>

                <div
                  id="CardLabOrderQueueUX-div-79"
                  style={{padding: "0px 5px",}}>
                  วันที่ทำ Cross-Match
                </div>
                <div
                  id="CardLabOrderQueueUX-div-80">

                  <DateTextBox
                    id="CardLabOrderQueueUX-DateTextBox-81"
                    onChange={props.handleChangeDate("selectedStartDateCrossMatch")}
                    value={props.selectedStartDateCrossMatch}>
                  </DateTextBox>
                </div>
                <div
                  id="CardLabOrderQueueUX-div-82"
                  style={{padding: "0px 5px"}}>
                  ถึง
                </div>
                <div
                  id="CardLabOrderQueueUX-div-83">

                  <DateTextBox
                    id="CardLabOrderQueueUX-DateTextBox-84"
                    onChange={props.handleChangeDate("selectedEndDateCrossMatch")}
                    value={props.selectedEndDateCrossMatch}>
                  </DateTextBox>
                </div>
              </div>
              <div
                id="CardLabOrderQueueUX-div-87"
                style={{padding: "0px 5px"}}>
                {props.crossMatchSearchButton}
              </div>
            </div>
            <div
              id="CardLabOrderQueueUX-div-85">
              {props.crossMatchPrintButton}
            </div>
          </div>
          <Table
            data={props.labCrossMatchOrderQueue}
            getTrProps={props.rowPropsCrossMatch}
            headers={props.headerTitleCrossMatch}
            id="CardLabOrderQueueUX-Table-64"
            keys={props.headerKeyCrossMatch}
            minRows="10"
            showPagination={false}
            style={{height: "35vh"}}
            widths={props.widthCrossMatch}>
          </Table>
        </div>
      </div>
    )
}


export default CardLabOrderQueueUX

export const screenPropsDefault = {"headerKey":"hn,patient_name,doctor_name,date,arrive_time,highest_urgency,status_name","headerTitle":"HN,ชื่อ-สกุล,แพทย์,วันเวลาที่สั่ง,วันเวลารับเข้า,Urgency,สถานะ"}

/* Date Time : Wed Feb 19 2025 12:18:02 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\",padding: \"5px 0 0\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.labOrderQueue"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.columnProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "code",
          "value": "props.headerTitle"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Table-1"
        },
        "keys": {
          "type": "code",
          "value": "props.headerKey"
        },
        "minRows": {
          "type": "value",
          "value": "12"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: props.isShow?\"44vh\":props.tableHeight }"
        },
        "widths": {
          "type": "code",
          "value": "props.width"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-2"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-48"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", paddingBottom: \"5px\"}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-49"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-51"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flex: \"1\", alignItems: \"center\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-52"
        },
        "style": {
          "type": "code",
          "value": "{width: \"auto\", padding: \"0px 5px\",...(props.disableBloodBook && {display: \"none\"})}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Button",
      "parent": 52,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isShow? \"ปิดรายการจองเลือด\":\"แสดงรายการจองเลือด\""
        },
        "color": {
          "type": "code",
          "value": "props.isShow? \"pink\": \"green\""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Button-56"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickShowCrossMatch"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-62"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.isShow? \"flex\": \"none\", flexDirection: \"column\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 64,
      "name": "Table",
      "parent": 62,
      "props": {
        "data": {
          "type": "code",
          "value": "props.labCrossMatchOrderQueue"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowPropsCrossMatch"
        },
        "headers": {
          "type": "code",
          "value": "props.headerTitleCrossMatch"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Table-64"
        },
        "keys": {
          "type": "code",
          "value": "props.headerKeyCrossMatch"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"35vh\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.widthCrossMatch"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-75"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 75,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-76"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 5px\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-78"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flex: \"1\", alignItems: \"center\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ทำ Cross-Match"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-79"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 5px\",}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-80"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 81,
      "name": "DateTextBox",
      "parent": 80,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-DateTextBox-81"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedStartDateCrossMatch\")"
        },
        "value": {
          "type": "code",
          "value": "props.selectedStartDateCrossMatch"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-82"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 5px\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-83"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 84,
      "name": "DateTextBox",
      "parent": 83,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-DateTextBox-84"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedEndDateCrossMatch\")"
        },
        "value": {
          "type": "code",
          "value": "props.selectedEndDateCrossMatch"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 75,
      "props": {
        "children": {
          "type": "code",
          "value": "props.crossMatchPrintButton"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-85"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 76,
      "props": {
        "children": {
          "type": "code",
          "value": "props.crossMatchSearchButton"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-87"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 5px\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-88"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormGroup-92"
        },
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "FormField",
      "parent": 92,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-93"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "FormField",
      "parent": 92,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-94"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "FormField",
      "parent": 92,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-95"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "FormField",
      "parent": 92,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-96"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormField",
      "parent": 92,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-97"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 92,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-98"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormField",
      "parent": 92,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-99"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 92,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-100"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "Barcode"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-101"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-102"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-103"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": "นามสกุล"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-104"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "Encounter Type"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-107"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "Approve status"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-108"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "Input",
      "parent": 93,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Input-109"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedBarcode\")"
        },
        "placeholder": {
          "type": "value",
          "value": "Lab ID, Container ID"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedBarcode"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Input",
      "parent": 94,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Input-110"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedHN\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedHN"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Input",
      "parent": 95,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Input-111"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedFirstname\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedFirstname"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Input",
      "parent": 96,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Input-112"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedLastname\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", }"
        },
        "value": {
          "type": "code",
          "value": "props.selectedLastname"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartDate"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-113"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "code",
          "value": "props.EndDate"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-114"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Dropdown",
      "parent": 99,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": "ALL"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Dropdown-115"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedEncounterType\")"
        },
        "options": {
          "type": "code",
          "value": "props.encounterOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedEncounterType"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Dropdown",
      "parent": 100,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": "ALL"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Dropdown-116"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedApprove\")"
        },
        "options": {
          "type": "code",
          "value": "props.approveOption"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedApprove"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormGroup-117"
        },
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormField",
      "parent": 117,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-118"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "FormField",
      "parent": 117,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-119"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 117,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-120"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "FormField",
      "parent": 117,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-121"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "FormField",
      "parent": 117,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-122"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 117,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-123"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 117,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-124"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "FormField",
      "parent": 117,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-125"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-128"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทแลป"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-129"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": "ความเร่งด่วน"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-130"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกที่สั่ง"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-131"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผู้สั่ง"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-132"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "Dropdown",
      "parent": 120,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": "ALL"
        },
        "disabled": {
          "type": "code",
          "value": "props.checkSpecimenReject"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Dropdown-135"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.statusOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedStatus"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "Dropdown",
      "parent": 121,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": "ALL"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Dropdown-136"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedLabType\")"
        },
        "options": {
          "type": "code",
          "value": "props.labOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedLabType"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "Dropdown",
      "parent": 122,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": "ALL"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Dropdown-137"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedUrgency\")"
        },
        "options": {
          "type": "code",
          "value": "props.urgencyOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedUrgency"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "Dropdown",
      "parent": 123,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": "ALL"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledDivFilter|| false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Dropdown-138"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDivision"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "Dropdown",
      "parent": 124,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": "ALL"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Dropdown-139"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDoctor\")"
        },
        "options": {
          "type": "code",
          "value": "props.doctorOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDoctor"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 125,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-141"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormField",
      "parent": 92,
      "props": {
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-FormField-142"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-143"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.55rem\"}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "label",
      "parent": 142,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-label-146"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "label",
      "parent": 118,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-label-147"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "label",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-label-148"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "label",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่สั่ง"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-label-150"
        },
        "style": {
          "type": "code",
          "value": "{margin: 0}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "label",
      "parent": 98,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-label-151"
        },
        "style": {
          "type": "code",
          "value": "{margin: 0}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "label",
      "parent": 125,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-label-152"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-153"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", display: \"flex\", alignItems: \"center\", marginTop: \"-1.25rem\"}"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-div-154"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", display: \"flex\", alignItems: \"center\", marginTop: \"-1.25rem\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "Checkbox",
      "parent": 153,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkSpecimenReject"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Checkbox-155"
        },
        "label": {
          "type": "value",
          "value": "Specimen Rejection"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkSpecimenReject\")"
        },
        "style": {
          "type": "code",
          "value": "{maxWidth: \"max-content\",width: \"100%\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "Checkbox",
      "parent": 154,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkSpecimen"
        },
        "id": {
          "type": "value",
          "value": "CardLabOrderQueueUX-Checkbox-156"
        },
        "label": {
          "type": "value",
          "value": "Specimen ที่ไม่ได้จัดเก็บ"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkSpecimen\")"
        },
        "style": {
          "type": "code",
          "value": "{maxWidth: \"max-content\",width: \"100%\"}"
        }
      },
      "seq": 156,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardLabOrderQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "headerKey": "hn,patient_name,doctor_name,date,arrive_time,highest_urgency,status_name",
    "headerTitle": "HN,ชื่อ-สกุล,แพทย์,วันเวลาที่สั่ง,วันเวลารับเข้า,Urgency,สถานะ"
  },
  "width": 80
}

*********************************************************************************** */
