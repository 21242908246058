import React, {
  MutableRefObject,
  useState,
  useRef,
  useEffect,
  useMemo,
  CSSProperties,
} from "react";
import { Button, Dimmer, Loader, Modal } from "semantic-ui-react";
// Mui
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";

// UX
import ModXrayDetailUX from "./ModXrayDetailUX";
import DentalDiagram from "react-lib/apps/common/CUDENT/DentalDiagram";
import { ModXrayDetailType } from "./sequence/ImagingOrder";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

import CONFIG from "config/config";
import { useIntl } from "react-intl";

// Types
type ModXrayDetailProps = {
  onEvent: (e: any) => any;
  setProp: (key: any, value: any, callback?: ()=> any) => any;
  // data
  open: boolean;
  data?: ModXrayDetailType | null;
  clinicalFindingId?: number | null;
  clinicalFindingIndex?: number | null;
  clinicalFindings: Record<string, any>[];
  filterClinicalFindingIds: number[];
  masterData: Partial<{
    organ: { name: string; items: any[] };
    clinicaltags: { name: string; items: any[] };
  }>;
  // options
  eligibilityOptions?: Record<string, any>[];
  // CommonInterface
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // config
  readOnly?: boolean;
  allowedReadyToView?: boolean;
  // callback
  onClose: () => any;
  onSave?: (data: Partial<ModXrayDetailType>, btnAction?: string) => any;
};

export const URGENCY_STATUS = {
  ROUTINE: "ROUTINE",
  STAT: "STAT",
};

const URGENCY_OPTIONS = Object.values(URGENCY_STATUS).map((status) => ({
  key: status,
  value: status,
  text: status,
}));

const IMAGES = {
  photo_white: "/static/images/x-ray/photo-library-white.png",
  photo_blue: "/static/images/x-ray/photo-library-blue-opacity.png",
};

const BUTTON_ACTIONS = {
  SAVE: "SAVE",
};

const MOD_XRAY_DETAIL = "ModXrayDetail";

const ACTION_SAVE = `${MOD_XRAY_DETAIL}_${BUTTON_ACTIONS.SAVE}`;

const ModXrayDetail = (props: ModXrayDetailProps) => {
  const intl = useIntl();
  const [hideBabyTooth, setHideBabyTooth] = useState(true);
  const [detail, setDetail] = useState<Partial<ModXrayDetailType>>({});

  const mainRef = useRef() as MutableRefObject<HTMLDivElement>;

  // Effect
  useEffect(() => {
    setDetail(props.data || {});
  }, [props.data]);

  useEffect(() => {
    const clinical =
      props.clinicalFindings?.[props.clinicalFindingIndex as any];

    if (clinical) {
      handleChangeValue(null, {
        name: "locations_name",
        value: clinical.locations_name,
      });
    }
  }, [props.clinicalFindings, props.clinicalFindingIndex]);

  const showDiagram = useMemo(() => {
    return props.data?.group_type === "DENTAL";
  }, [props.data]);

  const disabledSave = useMemo(() => {
    const isReadyToViewInconsistent =
      (detail?.ready_to_view && !detail?.image_url) || // ready to view requires URL
      (!detail?.ready_to_view && !!detail?.image_url); // URL requires ready to view

    return (
      (props.readOnly && !props.allowedReadyToView) || // view only
      (isReadyToViewInconsistent && !CONFIG.PACS_BASE_URL)
    );
  }, [props.readOnly, props.allowedReadyToView, detail?.ready_to_view, detail?.image_url]);

  //  Handler
  const handleChangeValue = (e: any, data: any) => {
    const value = typeof data.checked === "boolean" ? data.checked : data.value;

    setDetail((detail) => ({ ...detail, [data.name]: value }));
  };

  const handleChangeImageUrl = (e: any, data: any) => {
    setDetail((detail) => ({
      ...detail,
      image_url: data.value,
      ready_to_view: !!data.value,
    }));
  };

  const handleToggleHideBabyTooth = () => {
    setHideBabyTooth(!hideBabyTooth);
  };

  const handleToggleReady = () => {
    handleChangeValue(null, {
      name: "ready_to_view",
      value: !detail.ready_to_view,
    });
  };

  const handleClose = () => {
    setDetail({});

    props.onClose();
  };

  const handleSave = () => {
    const data = {
      ...detail,
      locations_name:
        props.clinicalFindings?.[props.clinicalFindingIndex as any]
          ?.locations_name || "",
    };

    props.onSave?.(data, ACTION_SAVE);
  };

  return (
    <>
      <SnackMessage
        id="ModXrayDetail"
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${ACTION_SAVE}`, false);
        }}
        error={props.errorMessage?.[ACTION_SAVE]}
        success={null}
        languageUX={props.languageUX}
      />

      <Modal
        open={props.open}
        closeOnDimmerClick={true}
        style={{ width: "fit-content", minWidth: "45%" }}
        // callback
        onClose={handleClose}
      >
        <div ref={mainRef}>
          <ModXrayDetailUX
            // data
            detail={detail}
            locationsName={
              props.clinicalFindings?.[props.clinicalFindingIndex as any]?.locations_name
            }
            // options
            eligibilityOptions={props.eligibilityOptions}
            urgencyOptions={URGENCY_OPTIONS}
            // config
            hideBabyTooth={hideBabyTooth}
            hideLocation={!showDiagram}
            hideDiagram={!showDiagram}
            hideImageUrl={CONFIG.PACS_BASE_URL}
            readOnly={props.readOnly}
            allowReadyToView={props.allowedReadyToView}
            // callback
            onClickDeciduousTeeth={handleToggleHideBabyTooth}
            onChangeValue={handleChangeValue}
            onChangeImageUrl={handleChangeImageUrl}
            onClose={handleClose}
            onCancel={handleClose}
            // Element
            DentalDiagram={
              showDiagram && (
                <DentalDiagram
                  onEvent={props.onEvent}
                  // data
                  clinicalFindingId={props.clinicalFindingId}
                  clinicalFindingIndex={props.clinicalFindingIndex}
                  masterData={{
                    organ: props.masterData?.organ || { name: "", items: [] },
                    clinicaltags: props.masterData?.clinicaltags || {
                      name: "",
                      items: [],
                    },
                  }}
                  superteeth={[]}
                  clinicalFindings={props.clinicalFindings}
                  missingteeth={[]}
                  implantteeth={[]}
                  filterClinicalFindingIds={props.filterClinicalFindingIds}
                  // config
                  selectorMode={!props.readOnly}
                  hideBabyTooth={hideBabyTooth}
                  sub={"DiagnosisToday"}
                  showDrawingChecked={[]}
                  svgZoom={0.65}
                />
              )
            }
            ButtonReadyToView={
              <ButtonReadyToView
                id="ModXrayDetail-ButtonReadyToView"
                active={!!detail.ready_to_view}
                disabled={!props.allowedReadyToView}
                onClick={handleToggleReady}
              />
            }
            ButtonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleSave}
                // data
                paramKey={ACTION_SAVE}
                buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SAVE]}
                // config
                id="ModXrayDetail-ButtonLoadCheck-Save"
                disabled={disabledSave}
                color={"green"}
                name={BUTTON_ACTIONS.SAVE}
                size="medium"
                title="SAVE"
                basic={true}
              />
            }
          />
        </div>
      </Modal>
    </>
  );
};

/* ------------------------------------------------------ */

/*                   ButtonReadyToView;                   */

/* ------------------------------------------------------ */
type ButtonReadyToViewProps = {
  id?: string;
  active: boolean;
  // style
  style?: CSSProperties;
  checkboxSx?: CheckboxProps["sx"];
  // config
  hideLabel?: boolean;
  disabled?: boolean;
  loading?: boolean;
  // callback
  onClick?: () => any;
};

export const ButtonReadyToView = (props: ButtonReadyToViewProps) => {
  const handleClick = () => {
    if (!props.loading) {
      props.onClick?.();
    }
  };

  return (
    <Button
      id={`${props.id}-Button`}
      disabled={props.disabled}
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: props.active ? "#2185D0" : "transparent",
        border: "1px solid rgba(33, 133, 208, 0.5",
        padding: "0.3rem 0.75rem 0.3rem 0.55rem",
        cursor: props.loading ? "unset" : "pointer",
        minWidth: "max-content",
        ...(props.style || {}),
      }}
      onClick={handleClick}
    >
      <Dimmer
        active={props.loading}
        size="small"
        inverted
        style={{
          background: "transparent",
          padding: 0,
        }}
      >
        <Loader size="small" inline={true}></Loader>
      </Dimmer>

      <Checkbox
        id={`${props.id}-Checkbox`}
        disabled={props.disabled}
        checked={!!props.active}
        style={{
          padding: 0,
          marginRight: "0.5rem",
        }}
        sx={{
          color: "rgba(33, 133, 208, 0.5)",
          "& .MuiSvgIcon-root": { fontSize: "1.75rem" },
          "&.Mui-checked": {
            color: "white",
          },
          ...(props.checkboxSx || {}),
        }}
      />
      <img
        id={`${props.id}-Img`}
        src={props.active ? IMAGES.photo_white : IMAGES.photo_blue}
        alt={props.active ? "white gallery" : "blue gallery"}
        style={{ width: "25px" }}
      />
      {!props.hideLabel && (
        <span
          style={{
            color: props.active ? "white" : "rgba(33, 133, 208, 0.5)",
            marginLeft: "0.5rem",
          }}
        >
          Ready to view
        </span>
      )}
    </Button>
  );
};

ModXrayDetail.displayName = "ModXrayDetail";

export default React.memo(ModXrayDetail);
