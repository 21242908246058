import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
  MutableRefObject,
} from "react";
import { Icon, Label, Modal } from "semantic-ui-react";

import ReactTable from "react-table-6";
import withFixedColumns from "react-table-hoc-fixed-columns";

// Common
import ModAuthen from "react-lib/apps/common/ModAuthen"
import SnackMessage from "react-lib/apps/common/SnackMessage";

// UX Lab
import CardOPDLabSummaryUX from "./CardOPDLabSummaryUX";
import ModLabReport from "./ModLabReport";

// UI
import ModalLabChart from "react-lib/apps/LAB/ModalLabChart";
import ModalLabExamSummary from "./ModalLabExamSummary";
import ButtonEditRemark from "../TPD/ButtonEditRemark";

// Interface
import { State, RunSequence } from "./sequence/OPDLabSummary";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { useIntl } from "react-intl";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

// Types
type CardOPDLabSummaryProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  OPDLabSummarySequence?: State["OPDLabSummarySequence"];
  LabReportSequence?: any;
  // data
  django?: any;
  selectedPatient?: Record<string, any>;
  centralLabResultList?: {
    items?: Record<string, any>[];
  };
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  permissionLabResultConfidential?: boolean;
  patientInfo?: any;
  searchedItemListWithKey?: Record<string, any>;
  // options
  masterOptions?: Record<string, any>;
  // config
  type?: "CUMULATIVE_LAB_RESULT";
};

const fileIconStyle = {
  margin: 0,
  fontSize: "1.2rem",
  textAlign: "right",
  width: "100%",
  cursor: "pointer",
  zIndex: 1,
};

const BUTTON_ACTIONS = {
  SEARCH: "SEARCH",
} as const;

const CUMULATIVE_LAB_RESULT = "CUMULATIVE_LAB_RESULT";

const CARD_OPD_LAB_SUMMARY = "CardOPDLabSummary";

const getStyle = (status: string) =>
  status ? { color: "red", fontWeight: "bold" } : {};

const CardOPDLabSummary: React.FC<CardOPDLabSummaryProps> = (props) => {
  const [openModLab, setOpenModLab] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<Record<string, string>>({});
  const [initialDatetime, setInitialDatetime] = useState<string>("");
  const [modLabReportData, setModLabReportData] = useState<any>({
    open: false,
    lab_order_item_id: null,
  });
  const [modAuthenData, setModAuthenData] = useState<any>({
    open: false,
    product_id: null,
    patient: null,
  });

  useEffect(() => {
    props.runSequence({
      sequence: "OPDLabSummary",
      restart: true,
      byDate: props.type === CUMULATIVE_LAB_RESULT,
      action: BUTTON_ACTIONS.SEARCH,
      card: CARD_OPD_LAB_SUMMARY,
    });

    return () => {
      props.runSequence({ sequence: "OPDLabSummary", clear: true });
    };
  }, [props.type]);

  useEffect(() => {
    setCollapsed({});
  }, [
    props.OPDLabSummarySequence?.groupLabResult,
    props.OPDLabSummarySequence?.labName,
  ]);

  const encounterOptions = useMemo(() => {
    return (props.OPDLabSummarySequence?.encounterList || []).map((item) => ({
      key: item.id,
      value: item.id,
      text: `[${item.type}] [${item.number}] ${
        item.created.split(" ")?.[0]
      } - ${item.division_name} ${item.doctor_name}`,
    }));
  }, [props.OPDLabSummarySequence?.encounterList]);

  const encounterDate = useMemo(() => {
    const data = props.OPDLabSummarySequence || {};

    const encounter = data.encounterList?.find(
      (item) => item.id === data.encounterId
    );
    return encounter?.created.split(" ")?.[0] || "";
  }, [props.OPDLabSummarySequence]);

  const labNameOptions = useMemo(() => {
    const division = props.OPDLabSummarySequence?.divisionId;

    if (Array.isArray(division)) {
      const items = (props.OPDLabSummarySequence?.groupLabResult || []).filter(
        (item) => item.parent
      );

      return items.map((item: any) => ({
        key: item.code,
        value: item.code,
        text: item.name,
      }));
    } else {
      return [{ key: "all", value: "all", text: "All Lab" }];
    }
  }, [
    props.OPDLabSummarySequence?.groupLabResult,
    props.OPDLabSummarySequence?.divisionId,
  ]);

  const handleToggle = useCallback(
    (parentCode: string) => () => {
      setCollapsed((collapsed) => {
        if (collapsed[parentCode]) {
          delete collapsed[parentCode];
        } else {
          collapsed[parentCode] = parentCode;
        }

        return { ...collapsed };
      });
    },
    []
  );

  const handleOpenLabReport = useCallback(
    (id: any, date: string) => () => {
      props.setProp(
        "LabReportSequence",
        {
          LabReportToken: {},
          sequenceIndex: "Action",
        },
        () =>
          setModLabReportData({
            open: true,
            lab_order_item_id: id,
            date,
          })
      );
    },
    []
  );

  const filterLabResult = useMemo(() => {
    let items = props.OPDLabSummarySequence?.groupLabResult || [];
    const labName = props.OPDLabSummarySequence?.labName;

    // Filter by lab name เมื่อเลือก lab division
    items = Array.isArray(labName)
      ? items.filter(
          (item) => labName.includes(item.parent_code) || item.header
        )
      : items;

    return items.map((item) => ({
      ...item,
      code: item.header ? (
        <div
          style={{
            textTransform: "capitalize",
            fontWeight: "bold",
            marginLeft: "19px",
          }}
        >
          {item.code.toLowerCase()}{" "}
        </div>
      ) : item.toggle ? (
        <div>
          <Icon
            name={collapsed[item.parent_code] ? "caret right" : "caret down"}
            style={{ width: "15px", cursor: "pointer" }}
            onClick={handleToggle(item.parent_code)}
          />{" "}
          {item.code}{" "}
        </div>
      ) : (
        <div style={{ marginLeft: "19px" }}>{item.code}</div>
      ),
      lab_name: item.toggle ? <div style={{ fontWeight: "bold" }}> {item.name} </div> : item.name,
      result: (
        <DisplayResult
          value={item.result}
          status={item.result_status}
          critical={item.result_critical}
          comment={item.result_comment}
          file={item.result_file}
          // callback
          onClickFile={handleOpenLabReport(item.result_order_item_id, item.result_date_time)}
        />
      ),
      result_value: item.result,
      prev_result: (
        <DisplayResult
          value={item.prev_result}
          status={item.prev_result_status}
          critical={item.prev_result_critical}
          columnsStyle="37.5% 25% 37.5%"
          date={item.prev_date}
          comment={item.prev_result_comment}
          file={item.prev_file}
          // callback
          onClickFile={handleOpenLabReport(item.prev_order_item_id, item.prev_date_time)}
        />
      ),
      prev_value: item.prev_result,
      parent_code: item.parent_code,
      toggle: item.toggle,
    }));
  }, [
    collapsed,
    props.OPDLabSummarySequence?.groupLabResult,
    props.OPDLabSummarySequence?.labName,
  ]);

  const labResultList = useMemo(() => {
    return filterLabResult.filter(
      (item) => !(collapsed[item.parent_code] && !item.toggle)
    );
  }, [collapsed, filterLabResult]);

  const labDivisionOptions = useMemo(() => {
    let options: any[] = props.masterOptions?.labDivision || [];

    const labDivisionIds = (
      props.OPDLabSummarySequence?.allLabResultList || []
    ).map((item) => item.lab_division);

    options = options.filter((item) => labDivisionIds.includes(item.value));

    return [
      ...(typeof props.OPDLabSummarySequence?.divisionId === "string"
        ? [{ key: "all", value: "all", text: "All Divisions" }]
        : []),
      ...options,
    ];
  }, [
    props.masterOptions?.labDivision,
    props.OPDLabSummarySequence?.divisionId,
    props.OPDLabSummarySequence?.allLabResultList,
  ]);

  const handleChangeValue = async (e: any, v: any) => {
    props.runSequence({
      sequence: "OPDLabSummary",
      action: "CHANGE",
      name: v.name,
      value: v.value,
      byDate: props.type === CUMULATIVE_LAB_RESULT,
      card: CARD_OPD_LAB_SUMMARY,
    });
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "OPDLabSummary",
      action: "SEARCH",
      byDate: props.type === CUMULATIVE_LAB_RESULT,
      card: CARD_OPD_LAB_SUMMARY,
    });
  };

  const handleClickCell = (
    is_confidential: any,
    productId: number,
    dateTime: string
  ) => {
    setInitialDatetime(dateTime);
    if (is_confidential) {
      setModAuthenData({
        open: true,
        product_id: productId,
        patient: props.selectedPatient?.id,
      });
    } else {
      props.onEvent({
        message: "CentralLabResultList",
        params: { product_id: productId, patient: props.selectedPatient?.id },
      });
    }
  };

  const handleCloseLabChart = () => {
    props.setProp("centralLabResultList", null);
  };

  const handleTdProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return handleGetTrProps({
      state,
      rowInfo,
      column,
      instance,
      onDoubleClick: handleClickCell,
    });
  };

  const handleOpenModLab = () => {
    setOpenModLab(true);
  };

  const handleCloseModLab = () => {
    setOpenModLab(false);
  };

  const handleCloseModLabReport = () => {
    setModLabReportData({ open: false, lab_order_item_id: null });

    props.setProp("LabReportSequence", {
      sequenceIndex: null,
      selectedLabReport: null,
    });

    props.setProp("permissionLabResultConfidential", false);
  };

  // ---------- ModAuthen
  const handleDenyModAuthen = () => {
    setModAuthenData({ open: false, product_id: null, patient: null });
  };

  const handleApproveModAuthen = ({ username, password }: any) => {
    props.onEvent({
      message: "ListCentralLabResultConfidential",
      params: {
        product_id: modAuthenData?.product_id,
        patient: modAuthenData?.patient,
        username: username,
        password: password,
        cardKey: CARD_OPD_LAB_SUMMARY,
        onSuccess: handleDenyModAuthen,
      },
    });
  };

  console.log("CardOPDLabSummary", props);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_OPD_LAB_SUMMARY}`, null);
        }}
        error={props.errorMessage?.[CARD_OPD_LAB_SUMMARY]}
        success={null}
        languageUX={props.languageUX}
      />

      <CardOPDLabSummaryUX
        // data
        title={
          props.type === CUMULATIVE_LAB_RESULT
            ? "Cumulative Lab Result"
            : "OPD Lab Summary"
        }
        encounterId={props.OPDLabSummarySequence?.encounterId}
        fromEncounterId={props.OPDLabSummarySequence?.fromEncounterId}
        labResultList={labResultList}
        divisionId={props.OPDLabSummarySequence?.divisionId}
        labName={props.OPDLabSummarySequence?.labName}
        encounterDate={encounterDate}
        // options
        encounterOptions={encounterOptions}
        labDivisionOptions={labDivisionOptions}
        labNameOptions={labNameOptions}
        // config
        multipleLabName={Array.isArray(props.OPDLabSummarySequence?.divisionId)}
        multipleLabDivision={Array.isArray(
          props.OPDLabSummarySequence?.divisionId
        )}
        config={{
          hideClinicalSummary: props.type === CUMULATIVE_LAB_RESULT,
          disabledClinicalSummary:
            !props.django?.user?.role_types?.includes("DOCTOR") &&
            (!props.OPDLabSummarySequence?.labDetailList?.every(
              (item) => item.approved
            ) ||
              !props.OPDLabSummarySequence?.labDetailList?.length),
          showFromDate: props.type === CUMULATIVE_LAB_RESULT,
        }}
        // callback
        onChangeValue={handleChangeValue}
        onSearch={handleSearch}
        onClinicalSummary={handleOpenModLab}
        getTdProps={handleTdProps}
        getTheadThProps={handleGetTheadThProps}
        // element
        cumulativeLabTable={
          props.type === CUMULATIVE_LAB_RESULT ? (
            <LabResultTable
              data={labResultList}
              columns={props.OPDLabSummarySequence?.tableColumns || []}
              // callback
              onClickCell={handleClickCell}
              onClickFile={handleOpenLabReport}
            />
          ) : null
        }
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_OPD_LAB_SUMMARY}_${BUTTON_ACTIONS.SEARCH}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_OPD_LAB_SUMMARY}_${BUTTON_ACTIONS.SEARCH}`
              ]
            }
            // config
            fluid={true}
            color={"blue"}
            name={BUTTON_ACTIONS.SEARCH}
            size="medium"
            title="Filter"
          />
        }
        languageUX={props.languageUX}
      />

      {!!props.centralLabResultList && (
        <ModalLabChart
          centralLabResultList={props.centralLabResultList}
          labNameResultList={{
            items: props.OPDLabSummarySequence?.groupLabResult || [],
          }}
          genderName={props.selectedPatient?.gender_name}
          initialDatetime={initialDatetime}
          // callback
          onClose={handleCloseLabChart}
          languageUX={props.languageUX}
        />
      )}

      <ModalLabExamSummary
        setProp={props.setProp}
        onEvent={props.onEvent}
        // seq
        runSequence={props.runSequence}
        // data
        django={props.django}
        open={openModLab}
        labResultList={labResultList}
        labDetailList={props.OPDLabSummarySequence?.labDetailList}
        chatDetail={props.OPDLabSummarySequence?.chatDetail}
        doctorRecommends={props.OPDLabSummarySequence?.doctorRecommends}
        errorMessage={props.errorMessage}
        buttonLoadCheck={props.buttonLoadCheck}
        // config
        byDate={props.type === CUMULATIVE_LAB_RESULT}
        // callback
        onClose={handleCloseModLab}
        languageUX={props.languageUX}
      />

      <Modal
        open={modLabReportData?.open}
        style={{
          width: "80%",
          padding: "1rem 1rem",
          margin: "1rem 0 0",
          height: "95vh",
          overflowY: "auto",
        }}
        onClose={handleCloseModLabReport}
      >
        <ModLabReport
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          LabReportSequence={props.LabReportSequence}
          // options
          masterOptions={props.masterOptions}
          // data
          cardKey={CARD_OPD_LAB_SUMMARY}
          permissionLabResultConfidential={
            props.permissionLabResultConfidential
          }
          encounterId={props.OPDLabSummarySequence?.encounterId}
          // CommonInterface
          errorMessage={props.errorMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          patientInfo={props.patientInfo}
          selectedLabOrderWorking={{
            date: modLabReportData?.date,
          }}
          selectedLabOrderItem={modLabReportData?.lab_order_item_id}
          patientIdInitial={props.patientInfo?.patient_id}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // callback
          onModLabReport={handleCloseModLabReport}
          languageUX={props.languageUX}
        />
      </Modal>

      <ModAuthen
        titleName="อนุญาตให้เข้าถึงผลการทดสอบทางห้องปฏิบัติการ เฉพาะบุคคลที่มีสิทธิ์เท่านั้น"
        titleColor="blue"
        open={modAuthenData?.open}
        style={{ marginTop: "30vh" }}
        onDeny={handleDenyModAuthen}
        onApprove={handleApproveModAuthen}
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                     LabResultTable                     */

/* ------------------------------------------------------ */
type LabResultTableProps = {
  data: any[];
  columns: string[];
  // callback
  onClickCell?: (
    is_confidential: boolean,
    productId: number,
    dateTime: string
  ) => any;
  onClickFile?: (item: any, date: string) => (e: any) => any;
};

const FIXED_COLUMNS = [
  { title: "Code", key: "code", width: 205 },
  { title: "Lab Name", key: "lab_name" },
  { title: "Reference value", key: "ref_value" },
  { title: "Unit", key: "unit" },
];

const LabResultTable = React.memo((props: LabResultTableProps) => {
  const [width, setWidth] = useState<any>("");

  const divRef = useRef() as MutableRefObject<HTMLDivElement>;
  const rightRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    const handleResize = () => {
      if (rightRef.current && divRef.current) {
        setWidth(
          rightRef.current.getBoundingClientRect().right -
            divRef.current.getBoundingClientRect().left
        );
      }
    };

    if (divRef.current?.parentElement) {
      handleResize();

      new ResizeObserver(handleResize).observe(divRef.current.parentElement);
    }
  }, []);

  const addonsColumn = useMemo(() => {
    return props.columns.map(
      (column, index) =>
        ({
          Header: column,
          accessor: column,
          headerStyle: { whiteSpace: "unset" },
          addons: true,
          resizable: false,
          last: index === 0,
          Cell: (row: any) => {
            const data =
              row.original?.dates?.find((item: any) => item.key === column) ||
              {};

            return (
              <DisplayResult
                value={data.value}
                status={data.status}
                critical={data.critical}
                file={data.file}
                comment={data.comment}
                columnsStyle="57.5% 42.5%"
                paddingStyle="0px 10px 0 2.5px"
                // callback
                onClickFile={props.onClickFile?.(data.order_item_id, data.key)}
              />
            );
          },
        } as any)
    );
  }, [props.columns]);

  const fixedColumns = useMemo(() => {
    return FIXED_COLUMNS.map((item) => ({
      Header: item.title,
      accessor: item.key,
      headerStyle: { whiteSpace: "unset" },
      style: { whiteSpace: "unset" },
      fixed: "left",
      resizable: false,
      width: item.width,
    })).concat(addonsColumn);
  }, [props.columns, addonsColumn]);

  const handleTdProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return handleGetTrProps({
      state,
      rowInfo,
      column,
      instance,
      onDoubleClick: props.onClickCell,
    });
  };

  return (
    <div ref={divRef}>
      <div ref={rightRef} style={{ position: "fixed", right: 0 }}></div>
      <ReactTableFixedColumns
        // key={`resize-${width}`}
        // @ts-ignore
        data={props.data}
        columns={fixedColumns as any[]}
        showPagination={false}
        getNoDataProps={() => {
          return { style: { display: "none" } };
        }}
        style={{ height: "600px", width: `${width}px` }}
        pageSize={props.data.length > 15 ? props.data.length : 15}
        // subRowsKey={"children"}
        // callback
        getTdProps={handleTdProps}
        getTheadThProps={handleGetTheadThProps}
        sortable={false}
      />
    </div>
  );
});

/* ------------------------------------------------------ */

/*                      DisplayResult                     */

/* ------------------------------------------------------ */
export const DisplayResult = (props: any) => {
  const intl = useIntl();
  return (
    <div
      style={{
        display: "grid",
        padding: props.paddingStyle ?? "0 5px",
        gridTemplateColumns: props.columnsStyle ?? "57.5% 42.5%",
      }}
    >
      <div
        style={{
          ...getStyle(props.status),
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        {!props.value && props.file ? (
          <div style={{ fontSize: "0.8rem" }}>{intl.formatMessage({ id: "แนบไฟล์ PDF" })}</div>
        ) : (
          <span data-testid="display-result-value">{props.value}</span>
        )}
        {props.comment && (
          <ButtonEditRemark
            value={props.comment}
            readOnly
            trigger={
              <img
                alt="Notebook regular icon"
                src={"/static/images/notebook-regular-blue.png"}
                style={{
                  cursor: "pointer",
                  width: "18px",
                  float: "right",
                  marginRight: "0.5rem",
                }}
              />
            }
          />
        )}
      </div>
      <div
        style={{
          ...getStyle(props.status),
          display: "grid",
          gridTemplateColumns: "15px 15px 1fr",
          ...(props.statusStyle || {}),
        }}
      >
        <label data-testid="display-result-status">{props.status}</label>
        <div style={{ display: "flex", margin: !!props.labelStyle ? "auto" : "" }}>
          {props.critical && (
            <Label
              data-testid="display-result-critical"
              circular
              color={"yellow"}
              size="mini"
              style={{
                width: 15,
                height: 15,
                ...(props.labelStyle || {}),
              }}
            >
              <Icon name="warning" color="red" style={{ margin: 0 }} />
            </Label>
          )}
        </div>
        {props.file && (
          <Icon
            name="file pdf outline"
            color="blue"
            style={fileIconStyle}
            onClick={props.onClickFile}
          />
        )}
      </div>
      {props.date && <div style={{ textAlign: "right", fontSize: "0.95rem" }}>{props.date}</div>}
    </div>
  );
};

// Utils
export const handleGetTrProps = (params: {
  state: any;
  rowInfo: any;
  column: any;
  instance: any;
  onDoubleClick?: (
    is_confidential: boolean,
    productId: number,
    dateTime: string
  ) => any;
}) => {
  const original = params.rowInfo?.original || {};
  const Header = params.column?.Header;

  const data = original.dates?.find((item: any) => item.key === Header) || {};

  const isResult = Header?.search(/^Result /g) !== -1;
  const isPrev = Header?.search(/^Previous /g) !== -1;

  const labId = params.column?.addons ? data.lab_id : original.lab_id;
  const value = params.column?.addons
    ? data.value
    : isResult
    ? original.result_value
    : isPrev
    ? original.prev_value
    : "";
  const dateTime = params.column?.addons
    ? data.key
    : isResult
    ? original.result_date_time
    : isPrev
    ? original.prev_date_time
    : "";

  return {
    style: {
      backgroundColor: original.header
        ? "#c4ecf5"
        : Header === "Current Result"
        ? "rgba(138, 217, 235, 0.05)"
        : "",
      borderRight: params.column?.addons
        ? "1px solid rgba(93,188,210,0.5)"
        : "",
      cursor: value ? "pointer" : "",
    },
    onDoubleClick: () => {
      if (value) {
        params.onDoubleClick?.(original?.is_confidential, labId, dateTime);
      }
    },
  };
};

export const handleGetTheadThProps = (
  state: any,
  rowInfo: any,
  column: any
) => {
  return {
    style: {
      backgroundColor: column.HeaderColor
        ? column.HeaderColor
        : column.last
        ? ""
        : column.addons
        ? "white"
        : "#437682",
      padding: "8px 5px",
      borderRight: column?.addons ? "1px solid rgba(93,188,210,0.5)" : "",
      color: !column.last && column.addons ? "#3D3D3D" : "",
    },
  };
};

export default React.memo(CardOPDLabSummary);
